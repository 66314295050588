define("discourse/plugins/discourse-global-communities/discourse/components/carousel-v2", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember-decorators/component", "jquery", "discourse-common/lib/debounce", "discourse-common/lib/later", "discourse-common/utils/decorators", "discourse/plugins/discourse-global-communities/discourse/config/carousel-v2-config", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator"], function (_exports, _component, _object, _runloop, _component2, _jquery, _debounce, _later, _decorators, _carouselV2Config, _analyticsAnnotator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO (while_away_loading_indicator) CLEANUP: remove this file once we remove the feature flag
  const CarouselV2 = dt7948.c(class CarouselV2 extends _component.default {
    tilesPerPage = (() => _carouselV2Config.carouselV2Config.defaultTilesPerPage)();
    page = 0;
    isScrolling = false;
    tilesContainer = null;
    tiles = null;
    tileSpacing = null;
    tileWidth = null;
    touchStart = 0;
    touchEnd = 0;
    didInsertElement() {
      super.didInsertElement(...arguments);
      (0, _runloop.schedule)("afterRender", this, this._afterRenderEvent);

      // Recalculate widths and tilesPerPage on resize
      window.addEventListener("resize", this._handleResize);
      this.appEvents.on("carousel:update", this, this._updateItems);

      // mobile scroll events analytics
      this.addTouchListeners(this.element);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      document.removeEventListener("resize", this._handleResize);
      this.appEvents.off("carousel:update", this, this._updateItems);

      // mobile scroll events analytics
      this.removeTouchListeners(this.element);
    }
    addTouchListeners(element) {
      if (this.site.mobileView) {
        element.addEventListener("touchstart", this.touchStartSwipe, false);
        element.addEventListener("touchend", this.touchEndSwipe, false);
      }
    }
    removeTouchListeners(element) {
      if (this.site.mobileView) {
        element.removeEventListener("touchstart", this.touchStartSwipe, false);
        element.removeEventListener("touchend", this.touchEndSwipe, false);
      }
    }
    touchStartSwipe(e) {
      this.touchStart = e.changedTouches[0].screenX;
    }
    static #_ = (() => dt7948.n(this.prototype, "touchStartSwipe", [_decorators.bind]))();
    touchEndSwipe(e) {
      this.touchEnd = e.changedTouches[0].screenX;
      this.handleGesture();
    }
    static #_2 = (() => dt7948.n(this.prototype, "touchEndSwipe", [_decorators.bind]))();
    handleGesture() {
      if (Math.abs(this.touchEnd) < Math.abs(this.touchStart)) {
        (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_next", this.appEvents);
      } else {
        (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_previous", this.appEvents);
      }
    }
    didRender() {
      super.didRender(...arguments);

      // No tiles if items are empty
      if (!this.items?.length) {
        return;
      }
      this.tilesContainer = this.element.querySelector(".tiles-wrapper");
      this.tiles = this.tilesContainer?.querySelectorAll(".tile");
      this.tilesPerPage = this._calculateTilesPerPage();
      this.tileSpacing = this._calculateTileSpacing();
      this.tileWidth = this._calculateTileWidth();
      if (this.capabilities.touch && !this.element.querySelector(".buffer")) {
        // Issue: the mobile layout includes visual horizontal buffers. The last element's margin
        // in a flex layout with '-webkit-overflow-scrolling: touch', however, is ignored.
        // Workaround: add an empty, buffer tile at the end.
        const bufferTile = '<a class="buffer tile" />';
        this.tiles[0].parentNode.insertAdjacentHTML("beforeend", bufferTile);
      }
    }
    _updateItems() {
      // resets the items to cause a rerender to add watched icons
      this.set("items", [...this.items]);
    }
    static #_3 = (() => dt7948.n(this.prototype, "_updateItems", [_decorators.bind]))();
    get containerWidth() {
      return this.tilesContainer.offsetWidth;
    }
    _calculateTilesPerPage() {
      if (this.tiles.length > 1) {
        return _carouselV2Config.carouselV2Config.defaultTilesPerPage;
      } else {
        return 1;
      }
    }
    _calculateTileSpacing() {
      if (this.tileSpacing) {
        return this.tileSpacing;
      }
      if (this.tileWidth) {
        return this.tiles[0].offsetWidth - this.tileWidth;
      } else {
        return _carouselV2Config.carouselV2Config.defaultTileSpacing;
      }
    }
    _calculateTileWidth() {
      const calculatedTileSize = (this.tilesContainer.offsetWidth + this.tileSpacing) / this.tilesPerPage - this.tileSpacing;
      if (!this.capabilities.touch) {
        this.tiles.forEach(tile => {
          tile.style.width = calculatedTileSize + "px";
        });
      }
      return calculatedTileSize;
    }
    _handleResize() {
      if (this.capabilities.touch) {
        return;
      }
      this.tileWidth = this._calculateTileWidth();
      if (this.page > 0) {
        (0, _debounce.default)(this, this._goToPage, this.page, _carouselV2Config.carouselV2Config.debounceDelay);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "_handleResize", [_decorators.bind]))();
    _goToPage(page) {
      const outerTileWidth = this.tileWidth + this.tileSpacing;
      let tilesOnThisPage = 1;
      if (_carouselV2Config.carouselV2Config.startPagewithNewTile === false) {
        const pageCount = (this.tiles.length / this.tilesPerPage).toFixed(2);
        tilesOnThisPage = page === pageCount ? this.tiles.length % this.tilesPerPage : this.tilesPerPage;
      }
      (0, _jquery.default)(this.tilesContainer).animate({
        scrollLeft: this.tilesContainer.scrollLeft + tilesOnThisPage * outerTileWidth
      }, _carouselV2Config.carouselV2Config.paginationAnimationDuration, () => {
        this.isScrolling = false;
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "_goToPage", [_decorators.bind]))();
    _goToPreviousPage() {
      let tileWidth = this.tileWidth;
      if (_carouselV2Config.carouselV2Config.startPagewithNewTile === false) {
        tileWidth = this.containerWidth;
      }
      (0, _jquery.default)(this.tilesContainer).animate({
        scrollLeft: this.tilesContainer.scrollLeft - tileWidth - this.tileSpacing
      }, _carouselV2Config.carouselV2Config.paginationAnimationDuration, () => {
        this.isScrolling = false;
      });
    }
    _afterRenderEvent() {
      // Timeout is set to 500ms so that Discourse analytics plugin can load before this event is triggered
      (0, _later.default)(() => {
        (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_loaded", this.appEvents);
      }, 500);
    }
    static #_6 = (() => dt7948.n(this.prototype, "_afterRenderEvent", [_decorators.bind]))();
    shouldDisplayPagination(itemCount) {
      return !this.capabilities.touch && itemCount > this.tilesPerPage;
    }
    static #_7 = (() => dt7948.n(this.prototype, "shouldDisplayPagination", [(0, _decorators.default)("items.length")]))();
    shouldDisplayPreviousButton(page, itemCount) {
      return itemCount > this.tilesPerPage && page > 0;
    }
    static #_8 = (() => dt7948.n(this.prototype, "shouldDisplayPreviousButton", [(0, _decorators.default)("page", "items.length")]))();
    shouldDisplayNextButton(page, itemCount) {
      const lastVisibleItemIndex = Math.min(itemCount, _carouselV2Config.carouselV2Config.startPagewithNewTile ? Math.floor(this.tilesPerPage) * (page + 1) : Math.floor(this.tilesPerPage * (page + 1)));
      return itemCount > this.tilesPerPage && itemCount - lastVisibleItemIndex > 0;
    }
    static #_9 = (() => dt7948.n(this.prototype, "shouldDisplayNextButton", [(0, _decorators.default)("page", "items.length")]))();
    nextPage() {
      if (this.capabilities.touch || this.isScrolling) {
        return;
      }
      this.isScrolling = true;
      this.incrementProperty("page");
      this._goToPage(this.page);
      let position = this.page;
      let topic = this.items[position];
      (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_next", this.appEvents, {
        topic_id: topic.id,
        position,
        label: this.getTopicLabel(topic)
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "nextPage", [_object.action]))();
    previousPage() {
      if (this.capabilities.touch || this.isScrolling) {
        return;
      }
      this.isScrolling = true;
      this.decrementProperty("page");
      this._goToPreviousPage();
      let position = this.page;
      let topic = this.items[position];
      (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_previous", this.appEvents, {
        topic_id: topic.id,
        position,
        label: this.getTopicLabel(topic)
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "previousPage", [_object.action]))();
    getTopicLabel(topic) {
      return topic.tileLabel.replace("agc.while_you_were_away.labels.", "");
    }
  }, [(0, _component2.classNames)("carousel-v2")]);
  var _default = _exports.default = CarouselV2;
});