define("discourse/plugins/discourse-global-communities/discourse/initializers/default-category", ["exports", "@ember/service", "discourse/lib/plugin-api", "discourse/models/category", "discourse/models/permission-type"], function (_exports, _service, _pluginApi, _category, _permissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "agc-base-component";
  var _default = _exports.default = {
    name: "default-category",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        api.modifyClass("model:composer", {
          pluginId: PLUGIN_ID,
          siteSettings: (0, _service.inject)(),
          open(opts) {
            if (!opts) {
              opts = {};
            }
            const defaultCategory = _category.default.findById(this.siteSettings.default_composer_category_id) || null;
            let newCategoryID = null;

            // set category from site setting if permission allows
            newCategoryID = defaultCategory && defaultCategory.permission === _permissionType.default.FULL ? defaultCategory.id : null;

            // when in a category route, set route's category if permission allows
            if (opts.categoryId) {
              const currentCategory = _category.default.findById(opts.categoryId);
              if (currentCategory && currentCategory.permission === _permissionType.default.FULL) {
                newCategoryID = currentCategory.id;
              }
            }
            opts.categoryId = newCategoryID;
            return this._super(...arguments);
          }
        });

        // always load all categories in composer dropdown
        api.modifyClass("controller:composer", {
          pluginId: `${PLUGIN_ID} default-category`,
          open(opts) {
            if (!opts) {
              opts = {};
            }
            opts.disableScopedCategory = true;
            return this._super(...arguments);
          }
        });
      });
    }
  };
});