define("discourse/plugins/discourse-global-communities/discourse/components/circles/manage-profile-actions", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-circles/discourse/components/circles/delete-button", "discourse/plugins/discourse-circles/discourse/components/circles/manage-ownership-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _deleteButton, _manageOwnershipButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GcCircleUserSummary extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    goToConnect() {
      this.router.transitionTo("connect");
    }
    static #_2 = (() => dt7948.n(this.prototype, "goToConnect", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="gc-manage-profile-actions">
          <CirclesManageOwnershipButton
            @model={{@outletArgs.model}}
            @mode="transfer"
          />
          <CirclesDeleteButton
            @model={{@outletArgs.model}}
            @onAfterDelete={{this.goToConnect}}
          />
        </div>
      
    */
    {
      "id": "NKTpl9iL",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"gc-manage-profile-actions\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@model\",\"@mode\"],[[30,1,[\"model\"]],\"transfer\"]],null],[1,\"\\n      \"],[8,[32,1],null,[[\"@model\",\"@onAfterDelete\"],[[30,1,[\"model\"]],[30,0,[\"goToConnect\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/manage-profile-actions.js",
      "scope": () => [_manageOwnershipButton.default, _deleteButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GcCircleUserSummary;
});