define("discourse/plugins/discourse-global-communities/discourse/components/tv-tile", ["exports", "@ember/component", "@ember/object", "@ember/template"], function (_exports, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "",
    topic: null,
    backgroundImageStyle: (0, _object.computed)("topic.vertical_image_url", {
      get() {
        return (0, _template.htmlSafe)(`background-image: url(${this.topic.vertical_image_url});`);
      }
    })
  });
});