define("discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/notification-preferences-os-toast", ["exports", "@ember/object", "I18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-toast"], function (_exports, _object, _I18n, _agcToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class OsNotificationsDisabledToast extends _agcToast.default {
    get icon() {
      return "info";
    }
    get message() {
      return _I18n.default.t("notification_preferences_os_toast.message");
    }
    onClickLink(e) {
      e.preventDefault();
      this.args.close();
      window.ReactNativeWebView.postMessage(JSON.stringify({
        goToSettings: true
      }));
      return false;
    }
    static #_ = (() => dt7948.n(this.prototype, "onClickLink", [_object.action]))();
    get showLink() {
      return true;
    }
    get linkText() {
      return _I18n.default.t("notification_preferences_os_toast.link");
    }
  }
  _exports.default = OsNotificationsDisabledToast;
});