define("discourse/plugins/discourse-global-communities/discourse/components/tv-suggested-episodes", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "discourse/lib/url", "discourse/mixins/scroll-top", "discourse/models/topic"], function (_exports, _component, _object, _ajax, _url, _scrollTop, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    show: null,
    topics: null,
    init() {
      this._super(...arguments);
      if (this.model.is_tv_episode) {
        (0, _ajax.ajax)("/tv/new.json").then(response => {
          if (response.topics && response.topics.length) {
            this.setProperties({
              show: true,
              topics: response.topics.map(topic => {
                return _topic.default.create(topic);
              }),
              showSeeAllLink: response.topics.length > this.siteSettings.tv_carousel_tiles_per_page
            });
          }
        });
      }
    },
    clickSeeAll() {
      (0, _scrollTop.scrollTop)();
      _url.default.routeTo("/tv");
    }
  }, [["method", "clickSeeAll", [_object.action]]]));
});