define("discourse/plugins/discourse-global-communities/discourse/helpers/agc-category-expert-question-indicator", ["exports", "@ember/template", "discourse-common/lib/helpers", "I18n"], function (_exports, _template, _helpers, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = agcCategoryExpertQuestionIndicator;
  (0, _helpers.registerRawHelper)("agc-category-expert-question-indicator", agcCategoryExpertQuestionIndicator);
  function agcCategoryExpertQuestionIndicator(topic, currentUser) {
    if (!currentUser || topic.expert_post_group_names?.length) {
      return;
    }
    if (currentUser.staff || topic.creator && topic.creator.id === currentUser.id || currentUser.expert_for_category_ids.includes(topic.category_id)) {
      return (0, _template.htmlSafe)(`<a data-agc-analytics-ref="guide-question-pill" href="/search?q=is:category_expert_question" class='topic-list-category-expert-question'>${_I18n.default.t("category_experts.topic_list.question")}</a>`);
    }
  }
});