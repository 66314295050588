define("discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/tv-tile", ["exports", "@glimmer/component", "@ember/template", "@ember/component", "@ember/template-factory"], function (_exports, _component, _template2, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TvTile extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="tile tv-tile" ...attributes>
          {{! template-lint-disable no-invalid-link-text }}
          <a
            href={{@topic.url}}
            class="tile"
            title={{@topic.title}}
            style={{this.backgroundImageStyle}}
          ></a>
        </div>
      
    */
    {
      "id": "RZUJoink",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"tile tv-tile\"],[17,1],[12],[1,\"\\n\"],[1,\"      \"],[10,3],[15,6,[30,2,[\"url\"]]],[14,0,\"tile\"],[15,\"title\",[30,2,[\"title\"]]],[15,5,[30,0,[\"backgroundImageStyle\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@topic\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/tv-tile.js",
      "isStrictMode": true
    }), this))();
    get backgroundImageStyle() {
      return (0, _template2.htmlSafe)(`background-image: url(${this.args.topic.vertical_image_url});`);
    }
  }
  _exports.default = TvTile;
});