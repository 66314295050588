define("discourse/plugins/discourse-global-communities/discourse/components/agc/custom-footer", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-footer">
    <div class="agc-footer-container">
      <div class="agc-footer-logo">
        <img src={{this.logoUrl}} />
      </div>
      <div class="agc-footer-columns">
        <div class="agc-footer-col1">
          {{html-safe this.firstColItems}}
        </div>
        <div class="agc-footer-col2">
          {{html-safe this.secondColItems}}
        </div>
        <div class="agc-footer-col3"></div>
        <div class="agc-footer-col4">
          {{i18n "agc.footer.powered_by"}}
          {{! template-lint-disable no-invalid-link-text }}
          <a class="agc-footer-amazon" href="https://amazon.com"></a>
        </div>
      </div>
      <div class="agc-footer-copyright">
        {{i18n "agc.footer.copyright" year=this.currentYear}}
      </div>
    </div>
  </div>
  */
  {
    "id": "SFUQK3Y0",
    "block": "[[[10,0],[14,0,\"agc-footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"agc-footer-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"agc-footer-logo\"],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,0,[\"logoUrl\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"agc-footer-columns\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"agc-footer-col1\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[[30,0,[\"firstColItems\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"agc-footer-col2\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[[30,0,[\"secondColItems\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"agc-footer-col3\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"agc-footer-col4\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"agc.footer.powered_by\"],null]],[1,\"\\n\"],[1,\"        \"],[10,3],[14,0,\"agc-footer-amazon\"],[14,6,\"https://amazon.com\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"agc-footer-copyright\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"agc.footer.copyright\"],[[\"year\"],[[30,0,[\"currentYear\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/custom-footer.hbs",
    "isStrictMode": false
  });
  class AgcCustomFooter extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "logoUrl", [_tracking.tracked]))();
    #logoUrl = (() => (dt7948.i(this, "logoUrl"), void 0))();
    constructor() {
      super(...arguments);
      this.logoUrl = this.siteSettings.site_logo_url;
    }
    get currentYear() {
      return new Date().getFullYear();
    }
    get firstColItems() {
      return this.#markupFor("col1_links");
    }
    static #_3 = (() => dt7948.n(this.prototype, "firstColItems", [_object.computed]))();
    get secondColItems() {
      return this.#markupFor("col2_links");
    }
    static #_4 = (() => dt7948.n(this.prototype, "secondColItems", [_object.computed]))();
    #markupFor(linkSettingsName) {
      let markup = "";
      this.siteSettings[`footer_${linkSettingsName}`].split("|").forEach((link, index) => {
        link = link.trim();
        if (link) {
          const attributes = link.split(",").map(s => s.trim());
          let title;
          if (!attributes[0].startsWith("/")) {
            title = attributes.shift();
          }
          if (!title) {
            title = _I18n.default.t(`agc.footer.${linkSettingsName}.link${index + 1}_title`);
          }
          markup += `<a href="${attributes[0]}">${title}</a>`;
        }
      });
      return markup;
    }
  }
  _exports.default = AgcCustomFooter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcCustomFooter);
});