define("discourse/plugins/discourse-global-communities/discourse/components/agc/contacts/section", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="agc-contacts__section" data-section-id={{@id}}>
    {{#if this.heading}}
      <h3>{{this.heading}}</h3>
    {{/if}}
    <div class="agc-contacts__group">
      {{#each @items as |item|}}
        <Agc::Contacts::Row
          @name={{item.name}}
          @avatar={{item.avatar}}
          @title={{item.title}}
          @email={{item.email}}
          @chime={{item.chime}}
          @phone={{item.phone}}
        />
      {{/each}}
    </div>
  </section>
  */
  {
    "id": "a1tNvxvf",
    "block": "[[[10,\"section\"],[14,0,\"agc-contacts__section\"],[15,\"data-section-id\",[30,1]],[12],[1,\"\\n\"],[41,[30,0,[\"heading\"]],[[[1,\"    \"],[10,\"h3\"],[12],[1,[30,0,[\"heading\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"agc-contacts__group\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@name\",\"@avatar\",\"@title\",\"@email\",\"@chime\",\"@phone\"],[[30,3,[\"name\"]],[30,3,[\"avatar\"]],[30,3,[\"title\"]],[30,3,[\"email\"]],[30,3,[\"chime\"]],[30,3,[\"phone\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@id\",\"@items\",\"item\"],false,[\"if\",\"each\",\"-track-array\",\"agc/contacts/row\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/contacts/section.hbs",
    "isStrictMode": false
  });
  class AgcContactsSection extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get heading() {
      const groups = JSON.parse(this.siteSettings.agc_csv_contacts_groups);
      const primaryGroupIndex = groups.findIndex(group => group.group_name === this.args.primaryGroup);
      return _I18n.default.t(`amazon_contacts.group${primaryGroupIndex + 1}.section${this.args.id}_heading`, {
        defaultValue: null
      });
    }
  }
  _exports.default = AgcContactsSection;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcContactsSection);
});