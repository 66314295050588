define("discourse/plugins/discourse-global-communities/discourse/connectors/user-preferences-nav/agc-home-feed-nav", ["exports", "@glimmer/component", "discourse-common/lib/get-owner"], function (_exports, _component, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserNavPreferencesAgcHomeFeed extends _component.default {
    static shouldRender() {
      const currentUser = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:current-user");
      return currentUser?.agcFeatureFlags["enable_user_option_default_sorting"];
    }
  }
  _exports.default = UserNavPreferencesAgcHomeFeed;
});