define("discourse/plugins/discourse-global-communities/discourse/components/see-all-comments-button", ["exports", "@ember/component", "@ember/object", "discourse/lib/url", "discourse-common/utils/decorators"], function (_exports, _component, _object, _url, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    show(value) {
      return value === "amazon_responses" || value === "guided_responses";
    },
    onClick() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("filter")) {
        params.delete("filter");
        window.history.replaceState({}, "", `${location.pathname}?${params}`);
      }
      this.topic.postStream.cancelFilter();
      this.topic.postStream.refresh().then(() => {
        _url.default.jumpToPost("1", {
          anchor: "comments-container"
        });
        this.appEvents.trigger("enhanced_user_analytics:event", "page_view", {}, {
          clickRef: "CTA_see_all_comments"
        });
      });
    }
  }, [["method", "show", [(0, _decorators.default)("topic.filter")]], ["method", "onClick", [_object.action]]]));
});