define("discourse/plugins/discourse-global-communities/discourse/components/agc/topic-show-more/topic-list-item-expandable-excerpt", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "@ember/template", "discourse/lib/offset-calculator", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _didInsert, _willDestroy, _service, _template2, _offsetCalculator, _i18n, _decorators, _videojsInitializer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicListExpandableExcerpt extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcSeeMoreTracking", [_service.inject]))();
    #agcSeeMoreTracking = (() => (dt7948.i(this, "agcSeeMoreTracking"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked], function () {
      return false;
    }))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    #intersectionObserver = null;
    #lastViewportAnalyticsState = null;
    get isExpandButtonVisible() {
      const featureEnabled1 = this.currentUser.agcFeatureFlags?.topic_show_more_enabled;
      return featureEnabled1 && this.args.topic.first_post_cooked_text?.length > 0;
    }
    togglePreviewImage(element1, expanded1) {
      if (!element1) {
        return;
      }
      // the preview image is not a child of the element, so we need to find first a common parent
      // and then use it to hide the preview image
      const topicItem1 = element1.closest(".topic-list-item");
      if (topicItem1) {
        if (expanded1) {
          topicItem1.classList.add("hide-preview-image");
        } else {
          topicItem1.classList.remove("hide-preview-image");
        }
      }
    }
    postProcessCookedText(parentElement1) {
      this._preventAnchorClickPropagation(parentElement1);
      this._initializeVideoEnhancements(parentElement1);
    }
    static #_5 = (() => dt7948.n(this.prototype, "postProcessCookedText", [_decorators.bind]))();
    _preventAnchorClickPropagation(parentElement1) {
      // makes anchors in the cooked text work as expected
      // https://ne106ev82.ignitecommunity.com/t/longer-post-for-testing-show-more/19225
      parentElement1.querySelectorAll("a").forEach(el1 => {
        el1.addEventListener("click", e1 => {
          // prevent event bubbling to the parent element on anchor clicks in the cooked text
          e1.stopPropagation();
        });
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "_preventAnchorClickPropagation", [_decorators.bind]))();
    _initializeVideoEnhancements(parentElement1) {
      parentElement1.querySelectorAll("video").forEach(video1 => {
        (0, _videojsInitializer.default)(video1, this.appEvents).then(videojs1 => {
          const videoJsElement1 = videojs1.el();
          [video1, videoJsElement1].forEach(element1 => {
            if (element1) {
              element1.addEventListener("click", e1 => {
                // prevent that clicking on the video opens the topic
                e1.stopPropagation();
                e1.preventDefault();
              });
            }
          });
        });
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "_initializeVideoEnhancements", [_decorators.bind]))();
    toggleExpanded(e1) {
      e1.preventDefault();
      this.expanded = !this.expanded;
      this.togglePreviewImage(e1.currentTarget || e1.target, this.expanded);
      this.logToggleExpandedAnalytics(this.expanded);
    }
    static #_8 = (() => dt7948.n(this.prototype, "toggleExpanded", [_object.action]))();
    _initIntersectionObserver() {
      if (!("IntersectionObserver" in window) || this.#intersectionObserver) {
        return;
      }
      const offset1 = (0, _offsetCalculator.headerOffset)();
      const headerOffsetInPx1 = offset1 <= 0 ? "0px" : `-${offset1}px`;
      this.#intersectionObserver = new IntersectionObserver(entries1 => {
        entries1.forEach(entry1 => {
          const state1 = entry1.isIntersecting ? "in_viewport" : "out_of_viewport";
          if (this.#lastViewportAnalyticsState === state1) {
            return;
          }
          this.logViewportVisibilityAnalytics(state1);
          this.#lastViewportAnalyticsState = state1;
        });
      }, {
        threshold: [0.0, 1.0],
        rootMargin: `${headerOffsetInPx1} 0px 0px 0px`
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "_initIntersectionObserver", [_decorators.bind]))();
    _clearIntersectionObserver() {
      this.#intersectionObserver?.disconnect();
    }
    static #_10 = (() => dt7948.n(this.prototype, "_clearIntersectionObserver", [_decorators.bind]))();
    trackExpandedAnalytics(element1) {
      this._initIntersectionObserver();
      this.#intersectionObserver?.observe(element1);
    }
    static #_11 = (() => dt7948.n(this.prototype, "trackExpandedAnalytics", [_decorators.bind]))();
    stopTrackingExpandedAnalytics() {
      this._clearIntersectionObserver();
    }
    static #_12 = (() => dt7948.n(this.prototype, "stopTrackingExpandedAnalytics", [_decorators.bind]))();
    logToggleExpandedAnalytics(expanded1) {
      if (expanded1) {
        this.agcSeeMoreTracking.startTimerFor(this.args.topic.id);
      } else {
        this.agcSeeMoreTracking.stopTimerFor(this.args.topic.id);
      }
      this.appEvents.trigger("agc-analytics:log-event", "show-more:toggled-expanded", {
        expanded: expanded1,
        source: "topic",
        topic_id: this.args.topic.id
      });
    }
    static #_13 = (() => dt7948.n(this.prototype, "logToggleExpandedAnalytics", [_decorators.bind]))();
    logViewportVisibilityAnalytics(state1) {
      this.updateTrackingTime(state1);
      this.appEvents.trigger("agc-analytics:log-event", "show-more:viewport-tracking", {
        state: state1,
        source: "topic",
        topic_id: this.args.topic.id
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "logViewportVisibilityAnalytics", [_decorators.bind]))();
    updateTrackingTime(state1) {
      const inViewport1 = state1 === "in_viewport";
      if (inViewport1) {
        this.agcSeeMoreTracking.startTimerFor(this.args.topic.id);
      } else {
        this.agcSeeMoreTracking.stopTimerFor(this.args.topic.id);
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "updateTrackingTime", [_decorators.bind]))();
    static #_16 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable modifier-name-case }}
        {{#if this.isExpandButtonVisible}}
          {{#if this.expanded}}
            <div>
              <a href={{@topic.lastUnreadUrl}}>
                <article
                  class="topic-show-more__expanded-cooked-text cooked"
                  data-topic-id={{@topic.id}}
                  data-post-id={{@topic.topic_first_post_id}}
                  {{didInsert this.postProcessCookedText}}
                  {{didInsert this.trackExpandedAnalytics}}
                  {{willDestroy this.stopTrackingExpandedAnalytics}}
                >
                  {{htmlSafe @topic.first_post_cooked_text}}
                </article>
              </a>
              <a
                href
                class="topic-show-more__btn --expanded"
                {{on "click" this.toggleExpanded}}
              >
                {{i18n "discourse_gc.show_less"}}
              </a>
            </div>
          {{else}}
            <p>
              <a href={{@topic.lastUnreadUrl}}>
                {{htmlSafe @topic.escapedExcerpt}}
              </a>
              <a
                href
                class="topic-show-more__btn"
                {{on "click" this.toggleExpanded}}
              >
                {{i18n "discourse_gc.show_more"}}
              </a>
            </p>
          {{/if}}
        {{else}}
          <p>
            <a href={{@topic.lastUnreadUrl}}>
              {{htmlSafe @topic.escapedExcerpt}}
            </a>
          </p>
        {{/if}}
      
    */
    {
      "id": "q654XZgM",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isExpandButtonVisible\"]],[[[41,[30,0,[\"expanded\"]],[[[1,\"        \"],[10,0],[12],[1,\"\\n          \"],[10,3],[15,6,[30,1,[\"lastUnreadUrl\"]]],[12],[1,\"\\n            \"],[11,\"article\"],[24,0,\"topic-show-more__expanded-cooked-text cooked\"],[16,\"data-topic-id\",[30,1,[\"id\"]]],[16,\"data-post-id\",[30,1,[\"topic_first_post_id\"]]],[4,[32,0],[[30,0,[\"postProcessCookedText\"]]],null],[4,[32,0],[[30,0,[\"trackExpandedAnalytics\"]]],null],[4,[32,1],[[30,0,[\"stopTrackingExpandedAnalytics\"]]],null],[12],[1,\"\\n              \"],[1,[28,[32,2],[[30,1,[\"first_post_cooked_text\"]]],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[11,3],[24,6,\"\"],[24,0,\"topic-show-more__btn --expanded\"],[4,[32,3],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n            \"],[1,[28,[32,4],[\"discourse_gc.show_less\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,\"\\n          \"],[10,3],[15,6,[30,1,[\"lastUnreadUrl\"]]],[12],[1,\"\\n            \"],[1,[28,[32,2],[[30,1,[\"escapedExcerpt\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[11,3],[24,6,\"\"],[24,0,\"topic-show-more__btn\"],[4,[32,3],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n            \"],[1,[28,[32,4],[\"discourse_gc.show_more\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"      \"],[10,2],[12],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"lastUnreadUrl\"]]],[12],[1,\"\\n          \"],[1,[28,[32,2],[[30,1,[\"escapedExcerpt\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/topic-show-more/topic-list-item-expandable-excerpt.js",
      "scope": () => [_didInsert.default, _willDestroy.default, _template2.htmlSafe, _modifier.on, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicListExpandableExcerpt;
});