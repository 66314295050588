define("discourse/plugins/discourse-global-communities/discourse/components/gc-category", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["category"],
    logoImageUrl(uploadedLogoUrl) {
      return uploadedLogoUrl || "";
    }
  }, [["method", "logoImageUrl", [(0, _decorators.default)("category.uploaded_logo.url")]]]));
});