define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs", ["exports", "discourse-common/lib/get-url", "I18n", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/deprecated-customize-widget-header"], function (_exports, _getUrl, _I18n, _customizeHeader, _deprecatedCustomizeWidgetHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcNavsCustomizations {
    static apply(api) {
      const currentUser = api.getCurrentUser();
      const siteSettings = api.container.lookup("service:site-settings");
      const programPillarsService = api.container.lookup("service:agc-program-pillars");
      const headerIcons = navItems(api, currentUser, siteSettings, programPillarsService);
      (0, _deprecatedCustomizeWidgetHeader.deprecatedCustomizeWidgetHeader)(api, headerIcons); // TODO delete when the header widget code is removed from core
      (0, _customizeHeader.customizeHeader)(api, headerIcons);

      // if program pillars is active, we move the Video option from nav bar to user menu
      const addVideoItem = programPillarsService.hasAny;
      if (addVideoItem) {
        api.addQuickAccessProfileItem({
          href: (0, _getUrl.default)("/tv"),
          className: "tv",
          icon: "upshift-tv",
          content: _I18n.default.t("agc.navs.tv")
        });
      }
      api.addQuickAccessProfileItem({
        href: (0, _getUrl.default)("/members"),
        className: "members-link",
        icon: "members-squid",
        content: _I18n.default.t("agc.navs.members")
      });
      siteSettings.user_dropdown_links.split("|").forEach((link, index) => {
        link = link.trim();
        if (link) {
          const attributes = link.split(",").map(s => s.trim());
          let content;
          let url;
          if (attributes[0].startsWith("https://")) {
            url = attributes[0];
          } else {
            url = (0, _getUrl.default)(attributes[0]);
          }
          if (!attributes[0].startsWith("/") && !attributes[0].startsWith("https://")) {
            content = attributes.shift();
          }
          if (!content) {
            content = _I18n.default.t(`agc.user_dropdown_links.link${index + 1}_title`);
          }
          api.addQuickAccessProfileItem({
            content,
            href: url,
            className: attributes[1],
            icon: attributes[2] || "blank"
          });
        }
      });
      if (currentUser) {
        if (currentUser.admin) {
          api.addQuickAccessProfileItem({
            href: (0, _getUrl.default)("/admin"),
            className: "admin-link",
            icon: "wrench",
            content: _I18n.default.t("admin_title")
          });
          api.addQuickAccessProfileItem({
            href: (0, _getUrl.default)("/review"),
            className: "review-link",
            icon: "flag",
            content: _I18n.default.t("review.title")
          });
        }
      }
    }
  }
  _exports.default = AgcNavsCustomizations;
  function navItems(api, currentUser, siteSettings, programPillarsService) {
    const site = api.container.lookup("service:site");
    const circlesService = api.container.lookup("service:circles");
    const chatEnabled = !!siteSettings.chat_enabled;
    // userCanChat is true if chat is enabled and the current user can chat even if the user
    // disabled chat in the preferences. We want to display the chat UI modifications in this case
    // to keep the UI consistent and display the call to action to enable chat in the preferences,
    // if the user clicks on the chat icon on the nav bar.
    const userCanChat = chatEnabled && currentUser?.can_chat;
    const userCanUseCircles = circlesService?.userCanUseCircles;
    const createPostNavItem = {
      id: "agc-create-post",
      type: "link",
      label: _I18n.default.t("agc.navs.post"),
      icon: "far-plus-square",
      action: () => {
        const container = api.container;
        if (container.lookup("service:current-circle")?.viewingCircle) {
          // if the post button is clicked inside a circle page
          container.lookup("route:circle").send("clickCreateTopicButton");
        } else {
          container.lookup("service:composer").openNewTopic({
            category: container.lookup("service:router").currentRoute?.attributes?.category
          });
        }
      },
      className: "create_post"
    };
    const tvNavItem = !!siteSettings.zoom_enabled && (!siteSettings.staff_only_broadcasts || currentUser.staff) && {
      id: "agc-tv",
      type: "link",
      label: _I18n.default.t("agc.navs.tv"),
      icon: "upshift-tv",
      href: "/tv",
      className: "tv",
      position: {
        before: ["agc-circles", "agc-members", "agc-chat"],
        after: "agc-create-post"
      }
    };
    const programPillarsNavItem = {
      id: "agc-program-pillars",
      type: "link",
      label: _I18n.default.t("agc.navs.program_pillars"),
      icon: "megaphone-icon",
      href: "/program-pillars",
      className: "program-pillars",
      position: {
        before: ["agc-circles", "agc-members", "agc-chat"],
        after: "agc-create-post"
      },
      showIndicator: true
    };
    const circlesNavItem = {
      id: "agc-circles",
      type: "link",
      label: _I18n.default.t("discourse_circles.index.title"),
      icon: "discourse_circles",
      href: "/connect",
      className: "circles",
      position: {
        before: ["agc-chat"],
        after: "agc-create-post"
      }
    };
    const membersNavItem = {
      id: "agc-members",
      type: "link",
      label: _I18n.default.t("agc.navs.members"),
      icon: "members-squid",
      href: "/members",
      className: "members",
      position: {
        before: "agc-chat",
        after: "agc-create-post"
      }
    };
    const chatNavItem = {
      id: "agc-chat",
      type: "chat",
      label: _I18n.default.t("chat.title_capitalized"),
      icon: "d-chat",
      href: "/chat",
      className: "chat",
      position: {
        after: "agc-create-post"
      }
    };
    const renderProgramPillars = programPillarsService.hasAny;
    const items = [];
    if (!site.mobileView) {
      items.push(createPostNavItem);
      if (renderProgramPillars) {
        items.push(programPillarsNavItem);
      } else {
        items.push(tvNavItem);
      }
      if (userCanUseCircles && userCanChat) {
        items.push(circlesNavItem, chatNavItem);
      } else if (userCanUseCircles) {
        items.push(circlesNavItem);
      } else if (userCanChat) {
        items.push(membersNavItem, chatNavItem);
      } else {
        items.push(membersNavItem);
      }
    } else {
      if (userCanChat) {
        items.push(chatNavItem);
      } else if (userCanUseCircles || siteSettings.show_members_link_in_mobile_nav_header) {
        items.push(membersNavItem);
      }
    }
    return items;
  }
});