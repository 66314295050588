define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-above-suggested/tv-suggestions", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{tv-suggested-episodes model=model}}
  */
  {
    "id": "XuWpEDuh",
    "block": "[[[1,[28,[35,0],null,[[\"model\"],[[30,0,[\"model\"]]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-above-suggested/tv-suggestions.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"tv-suggested-episodes\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-above-suggested/tv-suggestions.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});