define("discourse/plugins/discourse-global-communities/discourse/templates/components/carousel-v2-skeleton", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! TODO (while_away_loading_indicator) CLEANUP: remove this file once we remove the feature flag }}
  <div class="carousel-v2">
    <div class="carousel-container">
      <div class="tiles-wrapper">
        <div class="tiles">
  
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "M3G2m802",
    "block": "[[[10,0],[14,0,\"carousel-v2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"carousel-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tiles-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"tiles\"],[12],[1,\"\\n\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/carousel-v2-skeleton.hbs",
    "isStrictMode": false
  });
});