define("discourse/plugins/discourse-global-communities/discourse/components/agc/header/link", ["exports", "@ember/modifier", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _modifier, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AgcHeaderLink = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <a
      ...attributes
      href={{@href}}
      title={{@label}}
      data-agc-analytics-ref={{@agcAnalyticsRef}}
      {{(if @onClick (modifier on "click" @onClick))}}
    >
      {{#if @showIndicator}}
        <span class="agc-header-icon__indicator"></span>
      {{/if}}
      {{#if @icon}}
        <div class="header-icon">
          {{icon @icon}}
        </div>
      {{/if}}
      {{#unless @hideLabel}}
        <span>{{@label}}</span>
      {{/unless}}
      {{yield}}
    </a>
  
  */
  {
    "id": "XFNsTQeH",
    "block": "[[[1,\"\\n  \"],[11,3],[17,1],[16,6,[30,2]],[16,\"title\",[30,3]],[16,\"data-agc-analytics-ref\",[30,4]],[4,[52,[30,5],[50,[32,0],2,[\"click\",[30,5]],null]],null,null],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[10,1],[14,0,\"agc-header-icon__indicator\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,7],[[[1,\"      \"],[10,0],[14,0,\"header-icon\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[[30,7]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[51,[30,8]],[[[1,\"      \"],[10,1],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,9,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@href\",\"@label\",\"@agcAnalyticsRef\",\"@onClick\",\"@showIndicator\",\"@icon\",\"@hideLabel\",\"&default\"],false,[\"if\",\"modifier\",\"unless\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/header/link.js",
    "scope": () => [_modifier.on, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = AgcHeaderLink;
});