define("discourse/plugins/discourse-global-communities/discourse/initializers/agc-contacts", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/get-url", "I18n"], function (_exports, _pluginApi, _getUrl, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-contacts",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.10.1", api => {
        addNavigationItem(api, container);
        trackAnalytics(api);
      });
    }
  };
  function addNavigationItem(api, container) {
    const siteSettings = container.lookup("service:site-settings");
    if (siteSettings.agc_contacts_quick_access_item) {
      api.addQuickAccessProfileItem({
        href: (0, _getUrl.default)("/my/amazon-contacts"),
        className: "agc-contacts",
        icon: "amazon-contacts",
        content: _I18n.default.t("user.amazon_contacts")
      });
    }
  }
  function trackAnalytics(api) {
    const agcAnalyticsApi = api.agcAnalytics;

    // test if the analytics plugin api is available
    if (agcAnalyticsApi) {
      agcAnalyticsApi.registerClickAnalytics(".agc-contacts__row-contact-link", {
        eventType: "contacts:clicked-contact-link",
        eventData: element => {
          const type = element.dataset.contactType;
          const name = element.dataset.contactName;
          const title = element.dataset.contactTitle;
          const info = element.dataset.contactInfo;
          return {
            type,
            name,
            title,
            info
          };
        }
      });
    }
  }
});