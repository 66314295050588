define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/deprecated-split-widget-notification-menu", ["exports", "@ember/runloop", "virtual-dom", "discourse/widgets/header", "discourse/widgets/render-glimmer", "discourse/widgets/widget", "discourse-common/lib/icon-library", "I18n", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator", "@ember/template-factory"], function (_exports, _runloop, _virtualDom, _header, _renderGlimmer, _widget, _iconLibrary, _I18n, _analyticsAnnotator, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deprecatedSplitWidgetNotificationMenu = deprecatedSplitWidgetNotificationMenu;
  function deprecatedSplitWidgetNotificationMenu(api) {
    const siteSettings = api.container.lookup("service:site-settings");
    if (!siteSettings.chat_enabled) {
      return;
    }
    const site = api.container.lookup("service:site");
    if (!site.mobileView) {
      addNotificationsDropdownItem(api);
    }
    addProfileMenuHeaderPanel(api);
    splitMenusToggling(api);
    fixClosingMenus(api);
    changeUserDropdownClassnames(api);
  }
  function addProfileMenuHeaderPanel(api) {
    (0, _widget.createWidget)("standalone-user-menu-wrapper", {
      buildAttributes() {
        return {
          "data-click-outside": true
        };
      },
      html() {
        return [new _renderGlimmer.default(this, "div.widget-component-connector.user-menu", (0, _templateFactory.createTemplateFactory)(
        /*
          
                    <Agc::Header::ProfileMenu @closeUserMenu={{@data.closeUserMenu}}/>
        */
        {
          "id": "UqOQuAwx",
          "block": "[[[1,\"\\n            \"],[8,[39,0],null,[[\"@closeUserMenu\"],[[30,1,[\"closeUserMenu\"]]]],null]],[\"@data\"],false,[\"agc/header/profile-menu\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/deprecated-split-widget-notification-menu.js",
          "isStrictMode": false
        }), {
          closeUserMenu: this.closeStandaloneUserMenu.bind(this)
        })];
      },
      closeStandaloneUserMenu() {
        this.sendWidgetAction("toggleUserMenu");
      },
      clickOutside() {
        this.closeStandaloneUserMenu();
      }
    });
    api.addHeaderPanel("standalone-user-menu-wrapper", "standaloneUserMenuVisible", () => {});
  }
  function splitMenusToggling(api) {
    api.reopenWidget("header", {
      closeAll() {
        this._super(...arguments); // notifications, search, etc.
        this.state.standaloneUserMenuVisible = false; // standalone user menu
      },
      toggleSearchMenu() {
        this.state.searchVisible = false;
      },
      toggleNotificationsMenu() {
        (0, _analyticsAnnotator.triggerEnhancedNotificationsAnalytics)("btn_click", this.appEvents, {
          button: "nav_bar_notif_icon",
          action: this.state.userVisible ? "close" : "open"
        });
        this.state.userVisible = !this.state.userVisible;
        this.toggleBodyScrolling(this.state.userVisible);

        // auto focus on first button in dropdown
        (0, _runloop.schedule)("afterRender", () => document.querySelector(".user-menu button")?.focus());
      },
      _eventToggleNotificationsMenu() {
        // we need to use next to send the action because if the event is triggered by a click
        // outside the widget, a call to closeAll will be triggered just after the event is processed
        // and we need that the action be processed after that to prevent the menu from closing
        // immediately
        (0, _runloop.next)(this, () => this.sendWidgetAction("toggleNotificationsMenu"));
      },
      toggleUserMenu() {
        this.state.standaloneUserMenuVisible = !this.state.standaloneUserMenuVisible;
        this.toggleBodyScrolling(this.state.standaloneUserMenuVisible);

        // auto focus on first button in dropdown
        (0, _runloop.schedule)("afterRender", () => document.querySelector(".user-menu button")?.focus());
      },
      isUserMenuActive() {
        // we need to define this function to retrieve the user menu active status because using this
        // pattern the widget header-icons can access it using widget.callWidgetFunction
        return this.state.standaloneUserMenuVisible;
      },
      didRenderWidget() {
        if (!this.appEvents.has("header:toggle-notifications-menu")) {
          this.appEvents.on("header:toggle-notifications-menu", this, "_eventToggleNotificationsMenu");
        }
        return this._super();
      },
      destroy() {
        this.appEvents.off("header:toggle-notifications-menu", this, "_eventToggleNotificationsMenu");
        return this._super();
      }
    });
    api.reopenWidget("header-icons", {
      html() {
        return this._super(...arguments).map(item => {
          if (item.attrs.action === "toggleUserMenu") {
            item.attrs.active = this.callWidgetFunction("isUserMenuActive");
          }
          return item;
        });
      }
    });
  }
  function fixClosingMenus(api) {
    api.reopenWidget("revamped-user-menu-wrapper", {
      closeUserMenu() {
        this.sendWidgetAction("toggleNotificationsMenu");
      }
    });
    api.reopenWidget("header", {
      domClean() {
        this._super(...arguments);
        const {
          state
        } = this;
        if (state.standaloneUserMenuVisible) {
          this.closeAll();
        }
      }
    });
  }
  function addNotificationsDropdownItem(api) {
    (0, _widget.createWidget)("notifications-dropdown", {
      tagName: "li.agc-header-icon.notifications.header-dropdown-toggle",
      services: ["site"],
      buildId() {
        return "current-user-notifications";
      },
      html(attrs) {
        let items = [(0, _iconLibrary.iconNode)("bell-squid")];
        if (!this.site.mobileView) {
          items.push((0, _virtualDom.h)("span", _I18n.default.t("user.notifications")));
        }
        return (0, _virtualDom.h)("button.icon.btn-flat", {
          attributes: {
            "aria-haspopup": true,
            "aria-expanded": attrs.active,
            href: "/notifications",
            title: attrs.user.name || attrs.user.username,
            "data-auto-route": true
          }
        }, [items, this.attach("header-notifications", attrs)]);
      },
      ..._header.dropdown
    });
    api.reopenWidget("header-icons", {
      html(attrs) {
        const items = this._super(...arguments);
        if (attrs.user) {
          items.unshift(this.attach("notifications-dropdown", {
            active: attrs.userVisible,
            action: "toggleNotificationsMenu",
            user: attrs.user
          }));
        }
        return items;
      }
    });
  }
  function changeUserDropdownClassnames(api) {
    api.reopenWidget("user-dropdown", {
      tagName: "li.header-dropdown-toggle.current-user.user-menu-panel.standalone-user-menu"
    });
  }
});