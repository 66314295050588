define("discourse/plugins/discourse-global-communities/discourse/components/bulk-posts-errors", ["exports", "@glimmer/component", "discourse-common/lib/get-url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _getUrl, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BulkPostsErrors extends _component.default {
    constructor() {
      super(...arguments);
      this.errorPostURLs = this.args.model.errorPosts.map(errorPost1 => {
        return (0, _getUrl.getAbsoluteURL)(`/p/${errorPost1.post_id}`);
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div>
          <p>{{@model.message}}</p>
          {{#each this.errorPostURLs as |postURL|}}
            <li>
              <a
                href={{postURL}}
                target="_blank"
                rel="noopener noreferrer"
              >{{postURL}}</a>
            </li>
          {{/each}}
        </div>
      
    */
    {
      "id": "59s0uOjv",
      "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,2],[12],[1,[30,1,[\"message\"]]],[13],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"errorPostURLs\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,3],[15,6,[30,2]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[30,2]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"postURL\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/bulk-posts-errors.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BulkPostsErrors;
});