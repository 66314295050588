define("discourse/plugins/discourse-global-communities/discourse/components/topic-tile", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO (while_away_loading_indicator) CLEANUP: remove this file once we remove the feature flag
  var _default = _exports.default = _component.default.extend({
    tagName: "div",
    classNames: ["tile", "topic-tile"]
  });
});