define("discourse/plugins/discourse-global-communities/discourse/components/circles/logo-as-background", ["exports", "discourse/plugins/discourse-circles/discourse/components/circles/logo", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _logo, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @outletArgs.model.hasLogo}}
      <CirclesLogo @model={{@outletArgs.model}} />
    {{/if}}
  
  */
  {
    "id": "jaETM3pw",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"model\",\"hasLogo\"]],[[[1,\"    \"],[8,[32,0],null,[[\"@model\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/logo-as-background.js",
    "scope": () => [_logo.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});