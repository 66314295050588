define("discourse/plugins/discourse-global-communities/discourse/connectors/below-user-summary-stats/gc-circle-user-summary", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, context) {
      return context.siteSettings.discourse_circles_enabled && context.currentUser?.can_use_circles;
    },
    actions: {
      viewAll() {
        const router = (0, _application.getOwner)(this).lookup("service:router");
        router.transitionTo("user.circles");
      }
    }
  };
});