define("discourse/plugins/discourse-global-communities/discourse/connectors/user-main-nav/amazon-contacts-nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      const siteUserFields = component.site.user_fields;
      const userFields = args.model.user_fields;
      if (!siteUserFields?.length || !userFields) {
        return false;
      }
      const stationField = siteUserFields.findBy("name", component.siteSettings.agc_contacts_matching_user_field);
      if (!stationField) {
        return false;
      }
      const station = userFields[stationField.id];
      const excludeEntries = component.siteSettings.agc_contacts_exclude_entries.split("|");
      if (!station || excludeEntries.includes(station)) {
        return false;
      }
      return true;
    }
  };
});