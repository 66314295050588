define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/deprecated-customize-widget-header", ["exports", "jquery", "virtual-dom", "discourse/lib/url", "discourse/widgets/render-glimmer", "discourse-common/lib/icon-library", "I18n", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/deprecated-split-widget-notification-menu", "@ember/template-factory"], function (_exports, _jquery, _virtualDom, _url, _renderGlimmer, _iconLibrary, _I18n, _deprecatedSplitWidgetNotificationMenu, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deprecatedAddSearchWidget = deprecatedAddSearchWidget;
  _exports.deprecatedCustomizeWidgetHeader = deprecatedCustomizeWidgetHeader;
  function deprecatedCustomizeWidgetHeader(api, headerIcons) {
    const siteSettings = api.container.lookup("service:site-settings");
    if (siteSettings.glimmer_header_mode !== "disabled") {
      return;
    }
    const currentUser = api.getCurrentUser();
    const appEvents = api.container.lookup("service:app-events");
    api.createWidget("agc-link", {
      tagName: "a",
      buildClasses(attrs) {
        const result = [];
        result.push("widget-link");
        if (attrs.className) {
          result.push(attrs.className);
        }
        return result;
      },
      buildAttributes(attrs) {
        let baseAttrs = {
          href: attrs.href,
          title: attrs.title
        };
        if (attrs.agcAnalyticsRef) {
          baseAttrs["data-agc-analytics-ref"] = attrs.agcAnalyticsRef;
        }
        return baseAttrs;
      },
      html(attrs) {
        const result = [];
        if (attrs.icon) {
          result.push((0, _iconLibrary.iconNode)(attrs.icon));
          result.push(" ");
        }
        result.push((0, _virtualDom.h)("span.d-label", attrs.rawLabel));
        return result;
      },
      click(e) {
        e.preventDefault();
        this.sendWidgetAction("toggleDropdown");
        if (this.attrs.action) {
          return this.sendWidgetAction(this.attrs.action);
        } else {
          return _url.default.routeToTag((0, _jquery.default)(e.target).closest("a")[0]);
        }
      }
    });
    api.createWidget("agc-explore-dropdown-links", {
      tagName: "div.agc-dropdown-links.agc-explore-dropdown-links",
      html() {
        if (!this.siteSettings.explore_dropdown_links) {
          return [];
        }
        const links = [];
        const exploreDropdownLinks = JSON.parse(this.siteSettings.explore_dropdown_links);
        const site = api.container.lookup("service:site");
        const categories = site.get("categoriesList");
        const locale = _I18n.default.currentLocale();
        exploreDropdownLinks.forEach(l => {
          let rawLabel;
          if (locale === "de") {
            rawLabel = l.text_de;
          }
          if (locale === "fr") {
            rawLabel = l.text_fr;
          }
          if (!rawLabel) {
            rawLabel = l.text;
          }
          const link = {
            rawLabel,
            href: l.url
          };
          if (l.class) {
            link.className = l.class;
            link.agcAnalyticsRef = `nav_explore_${l.class.replace(/-/g, "_")}`;
          }

          // If it is a category, try to fill the missing fields using its
          // data. This is an effort to maintain the old behaviour.
          if (l.url.startsWith("/c/") || l.url.startsWith("/tags/c/")) {
            const parts = l.url.split("/").filter(Boolean);
            const categoryId = l.url.startsWith("/tags/c/") ? parseInt(parts[parts.length - 2], 10) : parseInt(parts[parts.length - 1], 10);
            const category = categories.find(c => c.id === categoryId);
            if (!category) {
              return;
            }
            link.href = category.url;
            if (!link.rawLabel) {
              link.rawLabel = category.name;
            }
            if (!link.className) {
              link.className = `agc-f-${category.slug}`;
            }
            if (!link.agcAnalyticsRef) {
              link.agcAnalyticsRef = `nav_explore_${category.slug.replace(/-/g, "_")}`;
            }
          }
          links.push(link);
        });
        return links.map(l => this.attach("agc-link", l));
      }
    });
    api.createWidget("agc-dropdown", {
      tagName: "div.agc-dropdown",
      buildKey: attrs => `agc-dropdown-${attrs.id}`,
      defaultState() {
        return {
          collapsed: true
        };
      },
      buildClasses(attrs) {
        const result = [attrs.id];
        if (!this.state.collapsed) {
          result.push("active");
        }
        return result;
      },
      html(attrs) {
        const items = [];
        items.push(this.attach("flat-button", {
          action: "toggleDropdown",
          icon: attrs.buttonIcon,
          contents: (0, _virtualDom.h)("span", attrs.buttonLabel),
          className: "icon"
        }));
        if (!this.state.collapsed) {
          items.push(this.attach(`agc-${attrs.id}-dropdown-links`));
        }
        return items;
      },
      toggleDropdown() {
        this.state.collapsed = !this.state.collapsed;
      },
      mouseDownOutside() {
        if (!this.state.collapsed) {
          this.sendWidgetAction("toggleDropdown");
        }
      }
    });
    const site = api.container.lookup("service:site");

    // this code technically has no real effect as AGC's communities require login to be viewed
    // that's why there is no version for glimmer_header_mode === "enabled"
    api.reopenWidget("header-buttons", {
      tagName: "span.header-buttons",
      html(attrs) {
        if (this.currentUser) {
          return;
        }
        const buttons = [];
        if (attrs.canSignUp && !attrs.topic) {
          buttons.push(this.attach("button", {
            label: "sign_up",
            className: "btn-primary btn-small sign-up-button",
            action: "showCreateAccount"
          }));
        }
        return buttons;
      }
    });
    api.decorateWidget("header-icons:before", helper => {
      if (!currentUser) {
        return [];
      }
      return headerIcons.map(navItem => {
        switch (navItem?.type) {
          case "link":
            // handles the create post icon separately
            if (navItem.id === "agc-create-post") {
              return prepareCreatePostNavItemH(navItem, {
                api,
                helper
              });
            }

            // all other links
            return prepareLinkNavItemH(navItem, {
              helper,
              site
            });
          case "chat":
            const unreadIndicator = currentUser.isInDoNotDisturb() ? null : new _renderGlimmer.default(helper.widget, "span", (0, _templateFactory.createTemplateFactory)(
            /*
              
                                <Chat::Header::Icon::UnreadIndicator />
            */
            {
              "id": "1veYFYCj",
              "block": "[[[1,\"\\n                    \"],[8,[39,0],null,null,null]],[],false,[\"chat/header/icon/unread-indicator\"]]",
              "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/deprecated-customize-widget-header.js",
              "isStrictMode": false
            }));
            return prepareLinkNavItemH(navItem, {
              helper,
              site,
              extraItems: unreadIndicator,
              extraClasses: ["header-dropdown-toggle", "chat-header-icon"]
            });
          default:
            return null;
        }
      }).reject(v => !v); // remove icons that fail the display condition
    });

    // disables all default header dropdowns except for the user dropdown
    api.reopenWidget("header-icons", {
      html() {
        // eslint-disable-next-line ember/no-ember-super-in-es-classes
        const items = this._super(...arguments).filter(item => item?.attrs?.action === "toggleUserMenu");
        return items;
      }
    });

    // disables search
    api.reopenWidget("header", {
      toggleSearchMenu() {
        this.state.searchVisible = false;
      }
    });
    if (currentUser) {
      if (site.mobileView) {
        api.reopenWidget("home-logo", {
          html() {
            return [];
          }
        });
      } else {
        api.decorateWidget("home-logo:after", helper => {
          if (!currentUser || !siteSettings.show_explore_dropdown) {
            return [];
          }
          const attrs = {
            id: "explore",
            buttonIcon: "chevron-down",
            buttonLabel: _I18n.default.t("agc.navs.explore")
          };
          return helper.attach("agc-dropdown", attrs);
        });
      }
    }
    const caps = api.container.lookup("service:capabilities");
    if (currentUser && caps.isIOS && caps.isAppWebview) {
      api.createWidget("header-back-button", {
        tagName: "div.header-back-button",
        html() {
          return this.attach("flat-button", {
            action: "goBack",
            icon: "chevron_left-squid"
          });
        },
        goBack() {
          window.history.back();
        }
      });
      api.decorateWidget("header-contents:before", helper => {
        return helper.attach("header-back-button");
      });
      appEvents.on("page:changed", route => {
        if (route.url === "/") {
          document.body.classList.remove("show-header-back-button");
        } else {
          document.body.classList.add("show-header-back-button");
        }
      });
    }
    api.modifyClass("component:site-header", {
      pluginId: "agc-navs-component",
      _leftMenuClass() {
        return this._isRTL() ? "hamburger-panel" : "user-menu";
      }
    });
    (0, _deprecatedSplitWidgetNotificationMenu.deprecatedSplitWidgetNotificationMenu)(api);
  }
  function prepareLinkNavItemH(data, options) {
    const {
      helper,
      site,
      extraItems = [],
      extraClasses = []
    } = options;
    let items = [(0, _iconLibrary.iconNode)(data.icon)];
    if (!site.mobileView) {
      items.push(helper.h("span", data.label));
    }
    items.push(extraItems);
    let contents = helper.h("a.icon.btn-flat", {
      href: data.href,
      title: data.label,
      attributes: {
        "data-agc-analytics-ref": `nav_${data.className}`
      }
    }, items);
    return helper.h(`li.agc-header-icon.${data.className}.${extraClasses.join(".")}`, contents);
  }
  function prepareCreatePostNavItemH(navItem, _ref3) {
    let {
      api,
      helper
    } = _ref3;
    api.createWidget("agc-post-button", {
      tagName: "li.agc-header-icon.agc-post-button",
      html(attrs) {
        const link = {
          action: "openComposer",
          rawLabel: attrs.buttonLabel,
          icon: attrs.buttonIcon,
          className: "icon",
          agcAnalyticsRef: "nav_create_post"
        };
        return this.attach("agc-link", link);
      },
      openComposer() {
        const container = api.container;
        if (container.lookup("service:current-circle")?.viewingCircle) {
          // if the post button is clicked inside a circle page
          container.lookup("route:circle").send("clickCreateTopicButton");
        } else {
          container.lookup("service:composer").openNewTopic({
            category: container.lookup("service:router").currentRoute?.attributes?.category
          });
        }
      }
    });
    const attrs = {
      id: navItem.id,
      buttonIcon: navItem.icon,
      buttonLabel: navItem.label
    };
    return helper.attach("agc-post-button", attrs);
  }
  function deprecatedAddSearchWidget(api) {
    api.createWidget("agc-search-menu", {
      tagName: "div.search-menu",
      html() {
        return [new _renderGlimmer.default(this, "div.widget-component-connector", (0, _templateFactory.createTemplateFactory)(
        /*
          
                    <SearchMenu />
        */
        {
          "id": "y4V29QC9",
          "block": "[[[1,\"\\n            \"],[8,[39,0],null,null,null]],[],false,[\"search-menu\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/deprecated-customize-widget-header.js",
          "isStrictMode": false
        }))];
      }
    });
    const site = api.container.lookup("service:site");
    const parentWidget = site.mobileView ? "header-contents:before" : "home-logo:after";
    api.decorateWidget(parentWidget, helper => {
      if (!helper.attrs.topic) {
        return helper.attach("agc-search-menu");
      }
    });
  }
});