define("discourse/plugins/discourse-global-communities/discourse/components/search-menu-result-types/circle", ["exports", "@glimmer/component", "@ember/template", "@ember/component", "@ember/template-factory"], function (_exports, _component, _template2, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchMenuResultCircle extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="search-result-single-circle">
          <div class="circles-top-row">
            {{#if this.logoComponent}}
              <this.logoComponent @model={{@result}} />
            {{/if}}
            <div class="circles-info">
              <div class="circles-name">{{@result.name}}</div>
              <p class="circles-description">{{this.descriptionText}}</p>
            </div>
          </div>
    
          <div class="circles-card-additional-info">
            {{#if this.statsComponent}}
              <this.statsComponent @model={{@result}} />
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "+V3i7o/6",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"search-result-single-circle\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"circles-top-row\"],[12],[1,\"\\n\"],[41,[30,0,[\"logoComponent\"]],[[[1,\"          \"],[8,[30,0,[\"logoComponent\"]],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,0],[14,0,\"circles-info\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"circles-name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n          \"],[10,2],[14,0,\"circles-description\"],[12],[1,[30,0,[\"descriptionText\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"circles-card-additional-info\"],[12],[1,\"\\n\"],[41,[30,0,[\"statsComponent\"]],[[[1,\"          \"],[8,[30,0,[\"statsComponent\"]],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@result\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/search-menu-result-types/circle.js",
      "isStrictMode": true
    }), this))();
    constructor() {
      super(...arguments);
      this.loadComponents();
    }
    loadComponents() {
      this.logoComponent = require("discourse/plugins/discourse-circles/discourse/components/circles/logo").default;
      this.statsComponent = require("discourse/plugins/discourse-circles/discourse/components/circles/card/stats").default;
    }
    get descriptionText() {
      return (0, _template2.htmlSafe)(this.args.result.category.description_excerpt || this.args.result.category.description);
    }
  }
  _exports.default = SearchMenuResultCircle;
});