define("discourse/plugins/discourse-global-communities/discourse/routes/connect", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ConnectRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    queryParams = {
      filter: {
        refreshModel: true
      }
    };
    beforeModel() {
      if (!this.currentUser?.can_use_circles) {
        this.router.replaceWith("members");
      }
    }
    model(params) {
      return params;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.load(model);
    }
  }
  _exports.default = ConnectRoute;
});