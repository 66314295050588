define("discourse/plugins/discourse-global-communities/discourse/connectors/above-topic-list/wywa-carousel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/application", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/models/topic", "discourse/models/user", "discourse-common/lib/later", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator"], function (_exports, _component, _tracking, _application, _object, _service, _ajax, _utilities, _topic, _user, _later, _analyticsAnnotator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Fetch topic details by topic id from Discourse api
   *
   * @param {Array.<string>} topicIds
   * @returns Topics from discourse api
   */
  async function fetchTopicsById(topicIds) {
    const topics = await (0, _ajax.ajax)(`/latest.json?topic_ids=${topicIds.join(",")}`);
    return buildTopics(topics?.topic_list?.topics, topics?.users);
  }

  /**
   * Builds renderable Topics
   *
   * @param {*} topicsFromTopicsList
   * @param {*} users
   * @returns
   */
  function buildTopics(topicsFromTopicsList, users) {
    const topics = [];
    topicsFromTopicsList?.forEach(function (topic) {
      topic?.posters?.forEach(function (poster) {
        poster.user = users?.find(user => user.id === poster.user_id);
      });
      topics.push(_topic.default.create(topic));
    });
    return topics;
  }

  /**
   * Return true if either the URL matches homepage URL, or the route matches defaultHomepage
   *
   * defaultHomepage uses <meta name='discourse_current_homepage'> to get the default homepage route.
   * For AGC instances, it is currently set to 'categories'.
   *
   * @param {Object} router
   * @returns {boolean}
   */
  function isHomepage(router) {
    const defaultRoute = (0, _utilities.defaultHomepage)() || "categories";
    const isRouteMatched = router.currentRouteName.split(".")[1] === defaultRoute;
    const isUrlMatched = router.currentURL.split("?")[0] === "/";
    return isRouteMatched || isUrlMatched;
  }
  class WywaCarousel extends _component.default {
    static shouldRender(args, component) {
      const router = (0, _application.getOwner)(component).lookup("service:router");
      const siteSettings = (0, _application.getOwner)(component).lookup("service:site-settings");
      const isFeaturedEnabled = siteSettings.enable_while_you_were_away;
      const isUserAuthorizedToView = _user.default.current().groups?.some(group => siteSettings.enable_while_you_were_away_groups.includes(group.name));
      return isHomepage(router) && (isFeaturedEnabled || isUserAuthorizedToView);
    }
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "visible", [_tracking.tracked], function () {
      return false;
    }))();
    #visible = (() => (dt7948.i(this, "visible"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "carouselTitleKey", [_tracking.tracked]))();
    #carouselTitleKey = (() => (dt7948.i(this, "carouselTitleKey"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "topicsSinceDays", [_tracking.tracked]))();
    #topicsSinceDays = (() => (dt7948.i(this, "topicsSinceDays"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "topics", [_tracking.tracked]))();
    #topics = (() => (dt7948.i(this, "topics"), void 0))();
    /**
     * Fetch recommended topics from recommendations api
     *
     * @returns {Promise<Array.<{communityId: string, contentId: string,contentSource: string, contentType: string, recommendationId: string, userId: string}>>}
     */
    async fetchRecommendedTopics() {
      return (0, _ajax.ajax)("/proxy/recommendations/v2/recommendations.json?recommendationType=WHILE_AWAY");
    }
    async loadTopics() {
      try {
        this.loading = true;
        const recommendedTopics = await this.fetchRecommendedTopics();
        if (!recommendedTopics) {
          return;
        }
        let topicIds = [];
        let tileLabels = new Map();
        for (const recommendation of recommendedTopics.recommendations) {
          const {
            contentId,
            recommendationLabel
          } = recommendation;
          topicIds.push(contentId);
          tileLabels.set(parseInt(contentId, 10), recommendationLabel.toLowerCase());
        }

        // If there aren't any recommended posts do not display anything
        if (!topicIds.length) {
          (0, _later.default)(() => {
            // Trigger analytics event even if recommendations are empty
            (0, _analyticsAnnotator.triggerRenderedWYWATopicsAnalytics)([], this.appEvents);
          }, 100);
          return;
        }
        const topics = await fetchTopicsById(topicIds);
        if (!topics.length) {
          return;
        }
        for (const topic of topics) {
          const label = tileLabels.get(topic.id);
          topic.tileLabel = `agc.while_you_were_away.labels.${label}`;
        }

        // sort topics in initial order
        const topicIdArray = topicIds.map(Number);
        topics.sort((a, b) => topicIdArray.indexOf(a.id) - topicIdArray.indexOf(b.id));

        // Setup our component with the topics from the array
        this.topics = topics;
        let days = parseInt(this.siteSettings.while_away_topics_since_minutes / 1440, 10);
        if (days === 0) {
          days = 1;
        }
        if (this.currentUser.seen_before) {
          this.carouselTitleKey = "agc.while_you_were_away.title";
        } else {
          this.carouselTitleKey = "agc.while_you_were_away.new_user_title";
          this.topicsSinceDays = days;
        }
        (0, _later.default)(() => {
          (0, _analyticsAnnotator.setWYWATopicsAnalyticsData)(".carousel-container", {
            isWYWATopic: true
          });
          (0, _analyticsAnnotator.triggerRenderedWYWATopicsAnalytics)(topics, this.appEvents);
        }, 100);
        this.visible = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "loadTopics", [_object.action]))();
  }
  _exports.default = WywaCarousel;
});