define("discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/saved-post-toast", ["exports", "@ember/object", "@ember/service", "I18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-toast"], function (_exports, _object, _service, _I18n, _agcToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SavedPostToast extends _agcToast.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    onClickLink(e) {
      e.preventDefault();
      this.router.transitionTo("userActivity.bookmarks", this.currentUser.username);
      this.args.close();
      return false;
    }
    static #_3 = (() => dt7948.n(this.prototype, "onClickLink", [_object.action]))();
    get icon() {
      return this.args.data.saved ? "check" : "info";
    }
    get message() {
      const type = _I18n.default.t(this.args.data.firstPost ? "saved_posts.toast.post" : "saved_posts.toast.comment");
      return _I18n.default.t(this.args.data.saved ? "saved_posts.toast.saved" : "saved_posts.toast.unsaved", {
        type
      });
    }
    get showLink() {
      return this.args.data.saved;
    }
    get linkHref() {
      return "/my/activity/bookmarks";
    }
    get linkText() {
      return _I18n.default.t("saved_posts.toast.view_saved");
    }
  }
  _exports.default = SavedPostToast;
});