define("discourse/plugins/discourse-global-communities/discourse/routes/program-pillars", ["exports", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/models/topic-list", "discourse/routes/discourse"], function (_exports, _service, _rsvp, _ajax, _topicList, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProgramPillarsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "agcProgramPillars", [_service.service]))();
    #agcProgramPillars = (() => (dt7948.i(this, "agcProgramPillars"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    queryParams = {
      t: {
        refreshModel: true
      }
    };
    async model(data) {
      const programPillar = this.agcProgramPillars.findByRegion(data.region);
      if (!programPillar) {
        return this.router.replaceWith("/404");
      }
      try {
        let [list, countsAndWidgetTopicLists] = await _rsvp.Promise.all([this.fetchTopicList(programPillar, data.t), this.fetchCountsAndWidgetTopicLists(programPillar, data.t)]);
        list = this.store.createRecord("topicList", _topicList.default.munge(list, this.store));
        countsAndWidgetTopicLists.child_tag_topic_counts.forEach(childTagCount => {
          const childTag = programPillar.child_tags.find(ct => ct.id === childTagCount.id);
          if (childTag) {
            childTag.count = childTagCount.count;
          }
        });
        programPillar.total_count = countsAndWidgetTopicLists.total_topic_count;
        programPillar.top_widget_topics = this.#convertToTopicModels(countsAndWidgetTopicLists.top_widget_topics);
        programPillar.bottom_widget_topics = this.#convertToTopicModels(countsAndWidgetTopicLists.bottom_widget_topics);
        list.set("programPillar", programPillar);
        return list;
      } catch (error) {
        // Return an empty model in the case that an ajax request errored out.
        // We have an empty state defined in the template.
        return;
      }
    }
    async fetchTopicList(config, childTag) {
      return await (0, _ajax.ajax)(this.#endpointWithChildTagParam(`/program-pillars/${config.region}/list.json`, childTag));
    }
    async fetchCountsAndWidgetTopicLists(config, childTag) {
      return await (0, _ajax.ajax)(this.#endpointWithChildTagParam(`/program-pillars/${config.region}.json`, childTag));
    }
    #endpointWithChildTagParam(endpoint, childTag) {
      if (childTag) {
        endpoint = `${endpoint}?child_tag_name=${childTag}`;
      }
      return endpoint;
    }
    #convertToTopicModels(topics) {
      return topics.map(t => this.store.createRecord("topic", t));
    }
  }
  _exports.default = ProgramPillarsRoute;
});