define("discourse/plugins/discourse-global-communities/discourse/components/gc-carousel", ["exports", "@ember/component", "@ember/object", "jquery", "discourse-common/utils/decorators"], function (_exports, _component, _object, _jquery, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTER_TILE_MARGIN = -4;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    topics: null,
    page: 0,
    init() {
      this._super(...arguments);
      this.set("tilesPerPage", this.siteSettings.tv_carousel_tiles_per_page);
    },
    showCarouselNav(topicsCount) {
      return !this.capabilities.touch && topicsCount > this.tilesPerPage;
    },
    showLeftCarouselNav(page, tiles) {
      return tiles > this.tilesPerPage && page > 0;
    },
    showRightCarouselNav(page, tiles) {
      return tiles > this.tilesPerPage && tiles - this.tilesPerPage * (page + 1) > 0;
    },
    moveRight() {
      if (this.capabilities.touch) {
        return;
      }
      this.incrementProperty("page");
      const tilesContainer = this.element.querySelector(".tiles");
      (0, _jquery.default)(tilesContainer).animate({
        scrollLeft: this.page * tilesContainer.offsetWidth + this.page * INTER_TILE_MARGIN
      }, 1000);
    },
    moveLeft() {
      if (this.capabilities.touch) {
        return;
      }
      if (this.page === 0) {
        return;
      }
      this.decrementProperty("page");
      const tilesContainer = this.element.querySelector(".tiles");
      (0, _jquery.default)(tilesContainer).animate({
        scrollLeft: (0, _jquery.default)(tilesContainer).scrollLeft() - (tilesContainer.offsetWidth + INTER_TILE_MARGIN)
      }, 600);
    }
  }, [["method", "showCarouselNav", [(0, _decorators.default)("topics.length")]], ["method", "showLeftCarouselNav", [(0, _decorators.default)("page", "topics.length")]], ["method", "showRightCarouselNav", [(0, _decorators.default)("page", "topics.length")]], ["method", "moveRight", [_object.action]], ["method", "moveLeft", [_object.action]]]));
});