define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-topic-list/wywa-carousel", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wywa-carousel" {{did-insert this.loadTopics}}>
    {{#if siteSettings.while_away_loading_indicator}}
      {{#if (or this.loading this.visible)}}
        <div class="carousel-title">
          {{#if this.loading}}
            <div
              class="text-placeholder animated-placeholder placeholder-animation"
            ></div>
          {{else}}
            {{i18n this.carouselTitleKey count=this.topicsSinceDays}}
          {{/if}}
        </div>
  
        <Agc::Carousel
          @loading={{this.loading}}
          @items={{this.topics}}
          @tileType="topic"
        />
      {{/if}}
    {{else}}
      {{#if this.visible}}
        <div class="carousel-title">
          {{i18n this.carouselTitleKey count=this.topicsSinceDays}}
        </div>
  
        <CarouselV2 @items={{this.topics}} @tileType="topic" />
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "HVNoJkhn",
    "block": "[[[11,0],[24,0,\"wywa-carousel\"],[4,[38,0],[[30,0,[\"loadTopics\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"while_away_loading_indicator\"]],[[[41,[28,[37,2],[[30,0,[\"loading\"]],[30,0,[\"visible\"]]],null],[[[1,\"      \"],[10,0],[14,0,\"carousel-title\"],[12],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"          \"],[10,0],[14,0,\"text-placeholder animated-placeholder placeholder-animation\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,3],[[30,0,[\"carouselTitleKey\"]]],[[\"count\"],[[30,0,[\"topicsSinceDays\"]]]]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\\n      \"],[8,[39,4],null,[[\"@loading\",\"@items\",\"@tileType\"],[[30,0,[\"loading\"]],[30,0,[\"topics\"]],\"topic\"]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"visible\"]],[[[1,\"      \"],[10,0],[14,0,\"carousel-title\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"carouselTitleKey\"]]],[[\"count\"],[[30,0,[\"topicsSinceDays\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,5],null,[[\"@items\",\"@tileType\"],[[30,0,[\"topics\"]],\"topic\"]],null],[1,\"\\n\"]],[]],null]],[]]],[13],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-topic-list/wywa-carousel.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"did-insert\",\"if\",\"or\",\"i18n\",\"agc/carousel\",\"carousel-v2\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-topic-list/wywa-carousel.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});