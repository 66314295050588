define("discourse/plugins/discourse-global-communities/discourse/routes/preferences-agc-home-feed", ["exports", "@ember/service", "discourse/routes/restricted-user"], function (_exports, _service, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesAgcHomeFeedRoute extends _restrictedUser.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    showFooter = true;
    setupController(controller, user) {
      if (!this.currentUser.agcFeatureFlags["enable_user_option_default_sorting"]) {
        return this.router.transitionTo("preferences");
      }
      controller.set("model", user);
    }
  }
  _exports.default = PreferencesAgcHomeFeedRoute;
});