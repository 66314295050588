define("discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/topic-list", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/homepage-topic-list", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/share-your-thoughts", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _homepageTopicList, _shareYourThoughts, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcDiscoveryTopicList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get shouldOverride() {
      return this.router.currentRouteName === "discovery.categories";
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldOverride}}
          <ShareYourThoughts />
    
          {{#if @outletArgs.model.topicList}}
            <HomepageTopicList @model={{@outletArgs.model.topicList}} />
          {{/if}}
        {{else}}
          {{yield}}
        {{/if}}
      
    */
    {
      "id": "Dzw8Liz0",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldOverride\"]],[[[1,\"      \"],[8,[32,0],null,null,null],[1,\"\\n\\n\"],[41,[30,1,[\"model\",\"topicList\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@model\"],[[30,1,[\"model\",\"topicList\"]]]],null],[1,\"\\n\"]],[]],null]],[]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@outletArgs\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/topic-list.js",
      "scope": () => [_shareYourThoughts.default, _homepageTopicList.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcDiscoveryTopicList;
});