define("discourse/plugins/discourse-global-communities/discourse/initializers/circles", ["exports", "@ember/object", "@ember/service", "@ember/string", "rsvp", "discourse/lib/plugin-api"], function (_exports, _object, _service, _string, _rsvp, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "agc-base-component";
  var _default = _exports.default = {
    name: "agc-circles",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.26.0", api => {
        const circlesService = container.lookup("service:circles");

        // circles may not be available in the forum instance
        if (circlesService) {
          api.modifyClass("route:user.summary", {
            pluginId: PLUGIN_ID,
            currentUser: (0, _service.inject)(),
            siteSettings: (0, _service.inject)(),
            async model() {
              const user = this.modelFor("user");

              // don't fetch circles for the summary if not needed
              if (!this.siteSettings.discourse_circles_enabled || !this.currentUser?.can_use_circles || user.get("profile_hidden")) {
                return this._super(...arguments);
              }
              const fetchParams = {
                type: "member",
                username: user.username,
                page_size: this.site.mobileView ? 3 : 6
              };
              const fetchSuper = await this._super(...arguments);
              const fetchCircles = await this.store.findAll("circle", fetchParams);
              const [_super, circles] = await _rsvp.Promise.all([fetchSuper, fetchCircles]);
              _super.circles = circles;
              _super.circles_current_user_summary = this.currentUser.id === user.id;
              return _super;
            }
          });
          api.modifyClass("controller:circle", dt7948.p({
            pluginId: PLUGIN_ID,
            get displayedTabs() {
              const order = ["activity", "messages", "requests", "about", "members", "manage"];
              return this.availableTabs.filter(tab => {
                return order.includes(tab.name);
              }).sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
            }
          }, [["method", "displayedTabs", [(0, _object.computed)("availableTabs")]]]));
          customizeUI(api);
          replaceIcons(api);
          trackCirclesAgcAnalytics(container);
        }
      });
    }
  };
  function customizeUI(api) {
    // Dynamic imports because the Circles plugin isn't always enabled.
    // Some of the connector components import components from the Circles plugin, if it's not enabled,
    // this import will cause errors.
    api.renderInOutlet("circles-card-stats", require("discourse/plugins/discourse-global-communities/discourse/components/circles/card-card-stats-with-privacy").default);
    api.renderInOutlet("circles-logo", require("discourse/plugins/discourse-global-communities/discourse/components/circles/logo-with-letter-fallback").default);
    api.renderInOutlet("circles-background", require("discourse/plugins/discourse-global-communities/discourse/components/circles/logo-as-background").default);
    api.renderInOutlet("circles-browser-navigation-header", require("discourse/plugins/discourse-global-communities/discourse/components/circles/simplified-navigation-header").default);
    api.renderInOutlet("circles-view-info-name", require("discourse/plugins/discourse-global-communities/discourse/components/circles/heading-name").default);
    api.renderInOutlet("circles-view-navigation-tabs", require("discourse/plugins/discourse-global-communities/discourse/components/circles/view-mobile-navigation-tabs").default);
    api.renderBeforeWrapperOutlet("circles-activity-topics-paginated-list", require("discourse/plugins/discourse-global-communities/discourse/components/circles/extra-activity-components").default);
    api.renderAfterWrapperOutlet("circles-manage-profile", require("discourse/plugins/discourse-global-communities/discourse/components/circles/manage-profile-actions").default);
  }
  function trackCirclesAgcAnalytics(container) {
    const appEvents = container.lookup("service:app-events");
    ["circle:join", "circle:leave", "circle:request_membership", "circle:membership_request_cancelled"].forEach(eventName => {
      appEvents.on(eventName, this, circle => appEvents.trigger("enhanced_user_analytics:event", (0, _string.underscore)(eventName.replace(":", "_")), {
        circle_category_id: circle.category_id,
        circle_slug: circle.slug,
        circle_name: circle.name
      }));
    });
    const currentUser = container.lookup("service:current-user");

    // Circle mentions are just regular group mentions - we have to do an
    // extra check
    if (currentUser?.can_use_circles) {
      appEvents.on("user-menu:notification-click", notification => {
        if (notification.data.circle_category_id) {
          appEvents.trigger("enhanced_user_analytics:event", "circle:mention-notification-click", {
            circle_category_id: notification.data.circle_category_id
          });
        }
      });
    }
  }
  function replaceIcons(api) {
    const icon = "circles-squid";
    api.replaceIcon("discourse_circles", icon);
    api.replaceIcon("notification.circles_activity", icon);
    api.replaceIcon("notification.circles_activity_request_membership_pm", icon);
  }
});