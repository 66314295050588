define("discourse/plugins/discourse-global-communities/discourse/components/circles/view-mobile-navigation-tabs", ["exports", "@glimmer/component", "@ember/helper", "discourse/components/plugin-outlet", "discourse/plugins/discourse-circles/discourse/components/circles/view/navigation-tabs", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _pluginOutlet, _navigationTabs, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesViewMobileNavigationTabs extends _component.default {
    shouldRender(args1, context1) {
      return context1.site.mobileView;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="circles-nav admin-main-nav">
          <ul class="nav nav-pills">
            {{#each @outletArgs.tabs as |tab|}}
              <PluginOutlet
                @name="circles-view-navigation-tab-item"
                @outletArgs={{hash
                  model=@outletArgs.model
                  tab=@tab
                  tabs=@outletArgs.tabs
                }}
              >
                <CirclesViewNavigationTab
                  @tab={{tab}}
                  @model={{@outletArgs.model}}
                />
              </PluginOutlet>
            {{/each}}
          </ul>
        </div>
      
    */
    {
      "id": "Ktn5wfkl",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"circles-nav admin-main-nav\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"tabs\"]]],null]],null],null,[[[1,\"          \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-view-navigation-tab-item\",[28,[32,1],null,[[\"model\",\"tab\",\"tabs\"],[[30,1,[\"model\"]],[30,3],[30,1,[\"tabs\"]]]]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,2],null,[[\"@tab\",\"@model\"],[[30,2],[30,1,[\"model\"]]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\",\"tab\",\"@tab\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/view-mobile-navigation-tabs.js",
      "scope": () => [_pluginOutlet.default, _helper.hash, _navigationTabs.Tab],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CirclesViewMobileNavigationTabs;
});