define("discourse/plugins/discourse-global-communities/discourse/components/hero-video", ["exports", "@ember/component", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer"], function (_exports, _component, _videojsInitializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    didInsertElement() {
      this._super(...arguments);
      const video = this.element.querySelector("video");
      if (video) {
        let transformedVideo = (0, _videojsInitializer.default)(video, this.appEvents);
        transformedVideo.on("play", this.onPlay);
        transformedVideo.on("pause", this.onPause);
      }
    }
  });
});