define("discourse/plugins/discourse-global-communities/discourse/components/gc-broadcasts", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["gc-broadcasts"],
    hasBroadcasts(l1, l2) {
      return l1 + l2 > 0;
    },
    myBroadcastsLink() {
      return `/u/${this.currentUser.username_lower}/activity/webinars`;
    }
  }, [["method", "hasBroadcasts", [(0, _decorators.default)("unregisteredTopics.length", "registeredTopics.length")]], ["method", "myBroadcastsLink", [_decorators.default]]]));
});