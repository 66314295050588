define("discourse/plugins/discourse-global-communities/discourse/components/agc/mobile-tab-bar/tab-button", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    role="button"
    style={{@width}}
    class={{this.className}}
    data-destination={{@tab.destination}}
    onclick={{@onClick}}
    data-agc-analytics-ref="mobile_nav"
  >
    {{#if @tab.showIndicator}}
      <span class="tab__indicator"></span>
    {{/if}}
    {{d-icon @tab.icon}}
    {{#if this.siteSettings.mobile_tab_bar_display_icon_titles}}
      <p class="title">{{@tab.title}}</p>
    {{/if}}
  
    {{#if @tab.showNotificationsBadge}}
      <Header::UserDropdown::Notifications />
    {{else if @tab.showUnreadPMBadge}}
      {{#if this.unreadPMs}}
        <span class="badge-notification unread-private-messages">
          {{this.unreadPMs}}
        </span>
      {{/if}}
    {{/if}}
  </a>
  */
  {
    "id": "G4kILY8s",
    "block": "[[[10,3],[14,\"role\",\"button\"],[15,5,[30,1]],[15,0,[30,0,[\"className\"]]],[15,\"data-destination\",[30,2,[\"destination\"]]],[15,\"onclick\",[30,3]],[14,\"data-agc-analytics-ref\",\"mobile_nav\"],[12],[1,\"\\n\"],[41,[30,2,[\"showIndicator\"]],[[[1,\"    \"],[10,1],[14,0,\"tab__indicator\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[28,[35,1],[[30,2,[\"icon\"]]],null]],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"mobile_tab_bar_display_icon_titles\"]],[[[1,\"    \"],[10,2],[14,0,\"title\"],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"showNotificationsBadge\"]],[[[1,\"    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,2,[\"showUnreadPMBadge\"]],[[[41,[30,0,[\"unreadPMs\"]],[[[1,\"      \"],[10,1],[14,0,\"badge-notification unread-private-messages\"],[12],[1,\"\\n        \"],[1,[30,0,[\"unreadPMs\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],null]],[]]],[13]],[\"@width\",\"@tab\",\"@onClick\"],false,[\"if\",\"d-icon\",\"header/user-dropdown/notifications\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/mobile-tab-bar/tab-button.hbs",
    "isStrictMode": false
  });
  class TabButton extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get unreadPMs() {
      return this.currentUser.get("unread_high_priority_notifications");
    }
    static #_3 = (() => dt7948.n(this.prototype, "unreadPMs", [(0, _object.computed)("currentUser.unread_high_priority_notifications")]))();
    get className() {
      let list = "tab";
      if (this.args.tab.className) {
        list = `${list} ${this.args.tab.className}`;
      }
      return list;
    }
  }
  _exports.default = TabButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TabButton);
});