define("discourse/plugins/discourse-global-communities/discourse/feature-flags/wywa-loading-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WywaLoadingIndicator {
    static enabledSetting = "while_away_loading_indicator";
    static groupsSetting = "while_away_loading_indicator_groups";
  }
  _exports.default = WywaLoadingIndicator;
});