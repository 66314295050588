define("discourse/plugins/discourse-global-communities/discourse/components/circles/card-card-stats-with-privacy", ["exports", "discourse/plugins/discourse-circles/discourse/components/circles/card/badges", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _badges, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <CirclesCardBadges
      class="circles-card__stats-item"
      data-stat-type="privacy"
      @model={{@outletArgs.model}}
    />
    {{yield}}
  
  */
  {
    "id": "bwT7Unvk",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"circles-card__stats-item\"],[24,\"data-stat-type\",\"privacy\"]],[[\"@model\"],[[30,1,[\"model\"]]]],null],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[\"@outletArgs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/card-card-stats-with-privacy.js",
    "scope": () => [_badges.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});