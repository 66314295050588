define("discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/notified-post-toast", ["exports", "I18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-toast"], function (_exports, _I18n, _agcToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NotifiedPostToast extends _agcToast.default {
    get icon() {
      return this.args.data.notified ? "check" : "info";
    }
    get message() {
      return _I18n.default.t(this.args.data.notified ? "notified_toast.notified" : "notified_toast.unnotified");
    }
  }
  _exports.default = NotifiedPostToast;
});