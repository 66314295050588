define("discourse/plugins/discourse-global-communities/discourse/templates/components/agc-quick-search-menu-see-all-results", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-update this.searchTermUpdated @searchTerm}}>
    {{#if this.show}}
      {{#if this.noResults}}
        <label class="no-results-label">
          {{i18n "agc.quick_search.no_results"}}
        </label>
      {{else}}
        <DButton
          @action={{this.navigateToAdvancedSearch}}
          @translatedLabel={{i18n "agc.quick_search.see_all_results"}}
          class="btn-primary see-all-results-btn"
        />
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "FR+yi25j",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"searchTermUpdated\"]],[30,1]],null],[12],[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[41,[30,0,[\"noResults\"]],[[[1,\"      \"],[10,\"label\"],[14,0,\"no-results-label\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"agc.quick_search.no_results\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],[[24,0,\"btn-primary see-all-results-btn\"]],[[\"@action\",\"@translatedLabel\"],[[30,0,[\"navigateToAdvancedSearch\"]],[28,[37,2],[\"agc.quick_search.see_all_results\"],null]]],null],[1,\"\\n\"]],[]]]],[]],null],[13]],[\"@searchTerm\"],false,[\"did-update\",\"if\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/agc-quick-search-menu-see-all-results.hbs",
    "isStrictMode": false
  });
});