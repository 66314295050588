define("discourse/plugins/discourse-global-communities/discourse/components/program-pillars-side-widget-footer", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/helpers/raw", "discourse/helpers/topic-link", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _raw, _topicLink, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProgramPillarsSideWidgetPostBadge extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked], function () {
      return false;
    }))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleExpanded", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="program-pillars-side-widget-footer-content">
          {{#if this.expanded}}
            {{#each @topics as |topic|}}
              <div class="program-pillars-side-widget links link">
                {{topicLink topic}}
                {{raw
                  "topic-post-badges"
                  unreadPosts=topic.unread_posts
                  unseen=topic.unseen
                  url=topic.lastUnreadUrl
                }}
              </div>
            {{/each}}
          {{/if}}
    
          <DButton
            class="btn-transparent program-pillars-side-widget-footer-btn"
            data-expanded={{this.expanded}}
            @action={{this.toggleExpanded}}
            @label={{if
              this.expanded
              "program_pillars.see_less"
              "program_pillars.see_all"
            }}
          />
        </div>
      
    */
    {
      "id": "QS5P6r7t",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"program-pillars-side-widget-footer-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"program-pillars-side-widget links link\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[[30,2]],null]],[1,\"\\n            \"],[1,[28,[32,1],[\"topic-post-badges\"],[[\"unreadPosts\",\"unseen\",\"url\"],[[30,2,[\"unread_posts\"]],[30,2,[\"unseen\"]],[30,2,[\"lastUnreadUrl\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null]],[]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"btn-transparent program-pillars-side-widget-footer-btn\"],[16,\"data-expanded\",[30,0,[\"expanded\"]]]],[[\"@action\",\"@label\"],[[30,0,[\"toggleExpanded\"]],[52,[30,0,[\"expanded\"]],\"program_pillars.see_less\",\"program_pillars.see_all\"]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@topics\",\"topic\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/program-pillars-side-widget-footer.js",
      "scope": () => [_topicLink.default, _raw.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ProgramPillarsSideWidgetPostBadge;
});