define("discourse/plugins/discourse-global-communities/discourse/templates/tv/tag", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="tv-tag">
    <div class="section">
      <div class="tiles">
        <h2 class="section-title">{{title}}</h2>
        {{#each topics as |topic|}}
          {{tv-tile topic=topic}}
        {{/each}}
      </div>
    </div>
  </div>
  */
  {
    "id": "f39XidT+",
    "block": "[[[10,0],[14,0,\"tv-tag\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tiles\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"section-title\"],[12],[41,[28,[32,0],[\"title\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"title\"],[[28,[32,1],[\"title\"],null]]]]],[[[1,[52,[30,1,[\"title\"]],[28,[30,1,[\"title\"]],null,null],[28,[32,2],[[30,0],\"title\",\"[\\\"The `title` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/tv/tag.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.title}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[13],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,6],null,[[\"topic\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,3],[\"[[\\\"The `topics` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/tv/tag.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\",\"topic\"],false,[\"if\",\"title\",\"let\",\"hash\",\"each\",\"-track-array\",\"tv-tile\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/tv/tag.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});