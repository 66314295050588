define("discourse/plugins/discourse-global-communities/discourse/services/agc-topic-list-watching", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/notification-levels"], function (_exports, _service, _ajax, _ajaxError, _notificationLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const normalLevel = _notificationLevels.topicLevels.find(tl => tl.key === "regular")?.id;
  const watchingLevel = _notificationLevels.topicLevels.find(tl => tl.key === "watching")?.id;
  class AgcTopicListWatchingService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "agcToasts", [_service.inject]))();
    #agcToasts = (() => (dt7948.i(this, "agcToasts"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "capabilities", [_service.inject]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "desktopNotifications", [_service.inject]))();
    #desktopNotifications = (() => (dt7948.i(this, "desktopNotifications"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    async toggleWatching(topic) {
      // Kinda weird but because we use this same function for toggling from topic view and topic list,
      // we need to look for `topic.notification_Level` AND `topic.details.notification_level`. This is
      // "hacky", but ensures that calling this fn will work from almost anywhere!
      // NOTE: We check details.notification_level first because there are edge cases where topic.notification_level
      // can get out of sync while details.notification_level is correct.
      const currentNotificationLevel = topic.details?.notification_level || topic.notification_level;
      const currentlyWatching = currentNotificationLevel === watchingLevel;
      const newLevel = currentlyWatching ? normalLevel : watchingLevel;
      try {
        await (0, _ajax.ajax)(`/t/${topic.id}/notifications`, {
          type: "POST",
          data: {
            notification_level: newLevel
          },
          returnXHR: true
        });
        topic.set("notification_level", newLevel);
        topic.set("details.notification_level", newLevel);
        this.showToast({
          notified: !currentlyWatching
        });
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
      return !currentlyWatching;
    }
    showToast() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        notified: true
      };
      // If we are in mobile browser (not app!) we don't want to link to preferences as we cannot
      // send PNs no matter what. Soooo just show success toasts.
      if (this.site.mobileView && !this.capabilities.isAppWebview) {
        return this.agcToasts.showSuccessTopicListNotifyToggleToast(opts);
      }

      // If we are showing the "You will no longer be updated.." toast, we will never link to
      // user preferences, so return early with the toast.
      if (!opts.notified || !this.currentUser.agcFeatureFlags.enhanced_notifications_enabled) {
        return this.agcToasts.showSuccessTopicListNotifyToggleToast(opts);
      }

      // Check the users' preferences and app settings and show a toast linking to
      // preferences or app settings to enable notifications if disabled.
      if (this.capabilities.isAppWebview) {
        // We are in the app - first check if BOTH discourse setting and OS settings are disabled
        if (!this.currentUser.user_option.allow_push_notifications && !window.pushNotificationsEnabled) {
          return this.agcToasts.showOsAndDiscourseNotificationsDisabledToast();
          // Then check if discourse preference is disabled
        } else if (!this.currentUser.user_option.allow_push_notifications) {
          // Discourse preference is disabled, so we show toast link to preferences page
          return this.agcToasts.showDiscourseNotificationsDisabledToast();
          // Finally check if OS setting is disabled
        } else if (!window.pushNotificationsEnabled) {
          // App settings (in OS) have notifications disabled, so show link to app settings.
          return this.agcToasts.showOsNotificationsDisabledToast();
        }
      }
      // If desktop notifications are supported, check desktop subscription and if false show toast to prompt
      else if (!this.desktopNotifications.isNotSupported && !this.desktopNotifications.isSubscribed) {
        return this.agcToasts.showDiscourseNotificationsDisabledToast();
      }
      this.agcToasts.showSuccessTopicListNotifyToggleToast(opts);
    }
  }
  _exports.default = AgcTopicListWatchingService;
});