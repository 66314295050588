define("discourse/plugins/discourse-global-communities/discourse/services/agc-discovery", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/utilities"], function (_exports, _tracking, _service, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcDiscoveryService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discovery", [_service.inject]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "gcFeatured", [_tracking.tracked], function () {
      return [];
    }))();
    #gcFeatured = (() => (dt7948.i(this, "gcFeatured"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "gcTopContributors", [_tracking.tracked], function () {
      return [];
    }))();
    #gcTopContributors = (() => (dt7948.i(this, "gcTopContributors"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "gcShareYourExpertise", [_tracking.tracked], function () {
      return [];
    }))();
    #gcShareYourExpertise = (() => (dt7948.i(this, "gcShareYourExpertise"), void 0))();
    get isHomepage() {
      return this.router.currentRouteName === `discovery.${(0, _utilities.defaultHomepage)()}`;
    }
    get isDiscoveryCategoryRoute() {
      const currentRoute = this.router.currentRouteName;
      return this.discovery.onDiscoveryRoute && currentRoute.toLowerCase().includes("category");
    }
  }
  _exports.default = AgcDiscoveryService;
});