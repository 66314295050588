define("discourse/plugins/discourse-global-communities/discourse/components/agc-quick-search-menu-see-all-results", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcQuickSearchMenuSeeAllResults extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "search", [_service.inject]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "show", [_tracking.tracked]))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "noResults", [_tracking.tracked]))();
    #noResults = (() => (dt7948.i(this, "noResults"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("agc-quick-search-menu:loaded", this, this.recalcShouldShow);
      this._lastSearchResult = this.search.activeGlobalSearchTerm;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("agc-quick-search-menu:loaded", this, this.recalcShouldShow);
    }
    searchTermUpdated() {
      // Hide right away if search term is updated, and let the quick search results component
      // fire an appEvent to show again. This is called if you click, on the search menu, which
      // is why we need to check if the search term was actually updated
      if (this._lastSearchResult !== this.search.activeGlobalSearchTerm) {
        this.show = false;
        this._lastSearchResult = this.search.activeGlobalSearchTerm;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "searchTermUpdated", [_object.action]))();
    recalcShouldShow(appEventArgs) {
      this.show = appEventArgs.show && !this.args.inTopicContext;
      this.noResults = appEventArgs.noResults;
    }
    static #_7 = (() => dt7948.n(this.prototype, "recalcShouldShow", [_object.action]))();
    navigateToAdvancedSearch(e) {
      e?.preventDefault();
      this.args.closeSearchMenu();
      this.router.transitionTo("full-page-search", {
        queryParams: {
          q: this.search.activeGlobalSearchTerm
        }
      });
      const searchMenuInput = document.getElementById("search-term");
      if (searchMenuInput) {
        searchMenuInput.value = this.search.activeGlobalSearchTerm;
      }
      return false;
    }
    static #_8 = (() => dt7948.n(this.prototype, "navigateToAdvancedSearch", [_object.action]))();
  }
  _exports.default = AgcQuickSearchMenuSeeAllResults;
});