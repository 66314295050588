define("discourse/plugins/discourse-global-communities/discourse/components/agc-quick-search-menu-results", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "discourse/lib/search", "discourse-common/lib/debounce", "discourse-common/lib/later", "discourse-common/utils/decorators", "discourse/plugins/discourse-global-communities/discourse/components/search-menu-result-types/circle", "discourse/plugins/discourse-global-communities/discourse/components/search-menu-result-types/topic"], function (_exports, _component, _tracking, _object, _runloop, _service, _search3, _debounce, _later, _decorators, _circle, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcQuickSearchMenuResults extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "search", [_service.inject]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "topicResults", [_tracking.tracked]))();
    #topicResults = (() => (dt7948.i(this, "topicResults"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "formattedTopicResults", [_tracking.tracked]))();
    #formattedTopicResults = (() => (dt7948.i(this, "formattedTopicResults"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "tvResults", [_tracking.tracked]))();
    #tvResults = (() => (dt7948.i(this, "tvResults"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "formattedTvResults", [_tracking.tracked]))();
    #formattedTvResults = (() => (dt7948.i(this, "formattedTvResults"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "circleResults", [_tracking.tracked]))();
    #circleResults = (() => (dt7948.i(this, "circleResults"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "formattedCircleResults", [_tracking.tracked]))();
    #formattedCircleResults = (() => (dt7948.i(this, "formattedCircleResults"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "_loading", [_tracking.tracked]))();
    #_loading = (() => (dt7948.i(this, "_loading"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "_loadedTopics", [_tracking.tracked]))();
    #_loadedTopics = (() => (dt7948.i(this, "_loadedTopics"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "_loadedCircles", [_tracking.tracked]))();
    #_loadedCircles = (() => (dt7948.i(this, "_loadedCircles"), void 0))();
    constructor() {
      super(...arguments);
      // If a suggested search is clicked, we want to debounce just like core does so that the
      // hiding logic still works nicely.
      const skipDebounce = this.search.activeGlobalSearchTerm.length < 3;
      this.attemptSearch({
        skipDebounce
      });
    }
    clearResultsIfTermIsInvalid() {
      if (!this.search.activeGlobalSearchTerm.length || !(0, _search3.isValidSearchTerm)(this.search.activeGlobalSearchTerm, this.siteSettings)) {
        this.clearResults();
      } else {
        this.attemptSearch();
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "clearResultsIfTermIsInvalid", [_object.action]))();
    attemptSearch() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        skipDebounce: false
      };
      if (this.lastSearch === this.search.activeGlobalSearchTerm) {
        return;
      }
      this.lastSearch = this.search.activeGlobalSearchTerm || "";
      this.clearResults();
      if ((0, _search3.isValidSearchTerm)(this.lastSearch, this.siteSettings)) {
        this.setLoading(true);
        this.debouncer = (0, _debounce.default)(this, this.performSearch, opts.skipDebounce ? null : 400);
      } else {
        this.setLoading(false);
      }
    }
    static #_16 = (() => dt7948.n(this.prototype, "attemptSearch", [_object.action]))();
    performSearch() {
      this.cancelActiveSearch();
      this.performCirclesSearch();
      this.performTopicsSearch();
    }
    performCirclesSearch() {
      if (!this.currentUser.can_use_circles) {
        this.circleResults = [];
        this._loadedCircles = true;
        return;
      }
      this._activeCirclesSearch = this.store.findAll("circle", {
        filter: this.search.activeGlobalSearchTerm,
        page_size: 3
      });
      this._activeCirclesSearch.then(circles => {
        circles.map(c => {
          c.path = `circles/${c.slug_path_with_id}`;
        });
        this.circleResults = circles.content;
        this.formattedCircleResults = [{
          component: _circle.default,
          componentName: "search-result-circle",
          type: "circle",
          results: this.circleResults
        }];
        this._loadedCircles = true;
        this.afterResultsLoaded();
      }).catch(() => {
        this.circleResults = [];
        this._loadedCircles = [];
        this.afterResultsLoaded();
      });
    }
    performTopicsSearch() {
      this._activeSearch = (0, _search3.searchForTerm)(this.search.activeGlobalSearchTerm);
      this._activeSearch.then(results => {
        const topicResults = results.resultTypes.find(r => {
          return r.componentName === "search-result-topic";
        });
        this.splitTopicQueryResults(topicResults?.results || []);
      }).catch(() => {
        this.topicResults = [];
        this.tvResults = [];
        this._loadedTopics = true;
        this.afterResultsLoaded();
      });
    }
    splitTopicQueryResults(results) {
      const topics = [];
      const tvEpisodes = [];
      results.forEach(t => {
        if (t.topic.is_tv_episode) {
          tvEpisodes.push(t);
        } else {
          topics.push(t);
        }
      });
      this.topicResults = topics.slice(0, 3);
      this.formattedTopicResults = [{
        component: _topic.default,
        componentName: "search-result-topic",
        type: "topic",
        results: this.topicResults
      }];
      this.tvResults = tvEpisodes.slice(0, 3);
      this.formattedTvResults = [{
        component: _topic.default,
        componentName: "search-result-video",
        type: "video",
        results: this.tvResults
      }];
      this._loadedTopics = true;
      this.afterResultsLoaded();
    }
    cancelActiveSearch() {
      if (this._activeSearch) {
        this._activeSearch.abort();
        this._activeSearch = null;
      }
      if (this._activeCirclesSearch) {
        this._activeCirclesSearch = null;
      }
      this._loadedTopics = false;
      this._loadedCircles = false;
    }
    afterResultsLoaded() {
      if (!this._loadedTopics || !this._loadedCircles) {
        return;
      }
      if (this.stillLoadingCoreResults) {
        return this.startResultsTimer();
      }
      (0, _runloop.cancel)(this._resultsTimer);
      (0, _runloop.next)(() => {
        let numberOfLoadedItems = this.topicResults.length + this.tvResults.length + this.circleResults.length;
        let startHidingItems = false;
        this.coreResultItems.forEach(item => {
          if (startHidingItems) {
            item.classList.add("hidden");
          } else {
            if (numberOfLoadedItems++ === 7) {
              startHidingItems = true;
            }
          }
        });
        this.coreResultGroups.forEach(container => {
          if (container && container.querySelectorAll(".item:not(.hidden)").length === 0) {
            container.classList.add("hidden");
          }
        });

        // Send appEvent so the "See All Results" component can determine if it should
        // show itself or not.
        this.appEvents.trigger("agc-quick-search-menu:loaded", {
          // show: numberOfLoadedItems || coreResultItems.length,
          show: true,
          noResults: !numberOfLoadedItems && !this.coreResultItems.length
        });
        this.setLoading(false);
      });
    }
    static #_17 = (() => dt7948.n(this.prototype, "afterResultsLoaded", [_decorators.bind]))();
    startResultsTimer() {
      this._resultsTimer = (0, _later.default)(this.afterResultsLoaded, 20);
    }
    get stillLoadingCoreResults() {
      return document.querySelector(".search-menu-container")?.classList.contains("loading");
    }
    get coreResultGroups() {
      return [document.querySelector(".search-menu .results .search-result-category"), document.querySelector(".search-menu .results .search-result-tag"), document.querySelector(".search-menu .results .search-result-user")];
    }
    get coreResultItems() {
      return document.querySelectorAll(".search-menu .results .search-result-category .item, .search-menu .results .search-result-tag .item, .search-menu .results .search-result-user .item");
    }
    setLoading(value) {
      this._loading = value;
      this.onLoadingChanged();
    }
    onLoadingChanged() {
      const results = document.querySelector(".search-menu .results");
      if (results) {
        results.classList.toggle("loaded", !this._loading);
      }
    }
    clearResults() {
      (0, _runloop.cancel)(this.debouncer);
      this.results = null;
      this._loadedTopics = false;
      this._loadedCircles = false;
      this.topicResults = null;
      this.formattedTopicResults = null;
      this.tvResults = null;
      this.formattedTvResults = null;
      this.circleResults = null;
      this.formattedCircleResults = null;
      this.appEvents.trigger("agc-quick-search-menu:loaded", {
        showSeeAllResultsButton: false
      });
    }
  }
  _exports.default = AgcQuickSearchMenuResults;
});