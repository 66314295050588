define("discourse/plugins/discourse-global-communities/discourse/components/gc-input-with-icon", ["exports", "@ember/component", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="input-with-icon">
    {{d-icon icon}}
    {{yield}}
  </div>
  */
  {
    "id": "k6kaYBGl",
    "block": "[[[10,0],[14,0,\"input-with-icon\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"icon\"]]],null]],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `icon` property path was used in the `discourse/plugins/discourse-global-communities/discourse/components/gc-input-with-icon.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.icon}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"&default\"],false,[\"d-icon\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/gc-input-with-icon.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: ""
  }));
});