define("discourse/plugins/discourse-global-communities/discourse/components/agc-bulk-posts-actions-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/helpers/reload-helper", "discourse/plugins/discourse-global-communities/discourse/components/bulk-change-tags", "discourse/plugins/discourse-global-communities/discourse/components/bulk-posts-errors", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template2, _dButton, _dModal, _ajax, _ajaxError, _i18n, _discourseI18n, _reloadHelper, _bulkChangeTags, _bulkPostsErrors, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcBulkPostsActionsModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "activeComponent", [_tracking.tracked], function () {
      return null;
    }))();
    #activeComponent = (() => (dt7948.i(this, "activeComponent"), void 0))();
    activeComponentOptions;
    actionButtons = [{
      label: "posts.bulk.append_tags",
      icon: "tag",
      class: "btn-default bulk-actions__append-tags",
      action: this.appendTagsAction
    }, {
      label: "posts.bulk.remove_tags",
      icon: "tag",
      class: "btn-default bulk-actions__remove-tags",
      action: this.removeTagsAction
    }, {
      label: "posts.bulk.replace_tags",
      icon: "tag",
      class: "btn-default bulk-actions__change-tags",
      action: this.replaceTagsAction
    }];
    constructor() {
      super(...arguments);
      this.postIds = this.args.model.postIds;
    }
    setComponent(component1) {
      this.activeComponent = component1;
    }
    static #_5 = (() => dt7948.n(this.prototype, "setComponent", [_object.action]))();
    appendTagsAction() {
      this.setComponent(_bulkChangeTags.default);
      const componentAction1 = async args1 => {
        const tags1 = args1.tags;
        await this.setPostTags("POST", this.postIds, tags1);
      };
      this.activeComponentOptions = {
        messageLabel: _discourseI18n.default.t("posts.bulk.choose_append_tags", {
          count: this.postIds.length
        }),
        buttonLabel: "posts.bulk.append_tags",
        action: componentAction1
      };
    }
    static #_6 = (() => dt7948.n(this.prototype, "appendTagsAction", [_object.action]))();
    replaceTagsAction() {
      this.setComponent(_bulkChangeTags.default);
      const componentAction1 = async args1 => {
        const tags1 = args1.tags;
        await this.setPostTags("PUT", this.postIds, tags1);
      };
      this.activeComponentOptions = {
        messageLabel: _discourseI18n.default.t("posts.bulk.choose_new_tags", {
          count: this.postIds.length
        }),
        buttonLabel: "posts.bulk.replace_tags",
        action: componentAction1
      };
    }
    static #_7 = (() => dt7948.n(this.prototype, "replaceTagsAction", [_object.action]))();
    removeTagsAction() {
      this.dialog.deleteConfirm({
        message: _discourseI18n.default.t("posts.bulk.confirm_remove_tags", {
          count: this.postIds.length
        }),
        didConfirm: async () => {
          await this.setPostTags("DELETE", this.postIds);
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "removeTagsAction", [_object.action]))();
    async setPostTags(requestType1, postIds1, tagNames1) {
      const options1 = {
        data: {
          post_ids: postIds1,
          tag_names: tagNames1
        },
        type: requestType1
      };
      try {
        const result1 = await (0, _ajax.ajax)(`/admin/plugins/agc/posts/tags.json`, options1);
        result1?.success_posts?.forEach(successPost1 => {
          this.appEvents.trigger(`display-post-tags-${successPost1.post_id}`, {
            success_post: successPost1
          });
          this.appEvents.trigger(`agc-custom:toggle-post-selector-${successPost1.post_id}`);
        });
        if (result1?.error_posts?.length === 0) {
          this.dialog.alert({
            message: _discourseI18n.default.t("posts.bulk.update_tags_success"),
            didConfirm: () => {
              (0, _reloadHelper.reloadPage)();
            }
          });
        } else {
          const errorPostIds1 = result1.error_posts.map(errorPost1 => errorPost1.post_id);
          this.dialog.confirm({
            bodyComponent: _bulkPostsErrors.default,
            bodyComponentModel: {
              message: _discourseI18n.default.t("posts.bulk.update_tags_error"),
              errorPosts: result1.error_posts
            },
            confirmButtonLabel: "posts.bulk.button.submit",
            cancelButtonLabel: "posts.bulk.button.cancel",
            didConfirm: async () => {
              await this.setPostTags(requestType1, errorPostIds1, tagNames1); // re-submit the request
            },
            didCancel: () => {
              this.args.closeModal();
            }
          });
        }
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "setPostTags", [_object.action]))();
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{i18n "posts.bulk.actions"}}
          @closeModal={{@closeModal}}
          class="bulk-posts-actions-modal -large"
        >
          <p>
            {{htmlSafe (i18n "posts.bulk.selected" count=this.postIds.length)}}
          </p>
          {{#if this.activeComponent}}
            <this.activeComponent @options={{this.activeComponentOptions}} />
          {{else}}
            <div class="action-buttons">
              {{#each this.actionButtons as |button|}}
                <DButton
                  @action={{button.action}}
                  @label={{button.label}}
                  @icon={{button.icon}}
                  class={{button.class}}
                />
              {{/each}}
            </div>
          {{/if}}
        </DModal>
      
    */
    {
      "id": "5sA61x2p",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"bulk-posts-actions-modal -large\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"posts.bulk.actions\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[32,2],[[28,[32,1],[\"posts.bulk.selected\"],[[\"count\"],[[30,0,[\"postIds\",\"length\"]]]]]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"activeComponent\"]],[[[1,\"        \"],[8,[30,0,[\"activeComponent\"]],null,[[\"@options\"],[[30,0,[\"activeComponentOptions\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"actionButtons\"]]],null]],null],null,[[[1,\"            \"],[8,[32,3],[[16,0,[30,2,[\"class\"]]]],[[\"@action\",\"@label\",\"@icon\"],[[30,2,[\"action\"]],[30,2,[\"label\"]],[30,2,[\"icon\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"button\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-bulk-posts-actions-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _template2.htmlSafe, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcBulkPostsActionsModal;
});