define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-more-content/agc-related-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id="agc-filtered-related-or-suggested-topics"
    {{did-insert this.removeSuggestedOrRelatedTopics}}
    {{did-update
      this.removeSuggestedOrRelatedTopics
      this.moreTopicsPreferenceTracking.topicLists
    }}
  >
  </div>
  */
  {
    "id": "McD93PF4",
    "block": "[[[11,0],[24,1,\"agc-filtered-related-or-suggested-topics\"],[4,[38,0],[[30,0,[\"removeSuggestedOrRelatedTopics\"]]],null],[4,[38,1],[[30,0,[\"removeSuggestedOrRelatedTopics\"]],[30,0,[\"moreTopicsPreferenceTracking\",\"topicLists\"]]],null],[12],[1,\"\\n\"],[13]],[],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-more-content/agc-related-topics.hbs",
    "isStrictMode": false
  });
});