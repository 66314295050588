define("discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/share-your-thoughts", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/service", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _service, _dIcon, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcDiscoveryShareYourThoughts extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.inject]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get shouldRender() {
      return this.router.currentRouteName !== "program-pillars";
    }
    shareYourThoughtsClicked() {
      localStorage.lastAgcAnalyticsRef = "share_your_thoughts";
      this.composer.openNewTopic({
        category: this.args.category
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "shareYourThoughtsClicked", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <button
            type="button"
            class="composer-prompt"
            {{on "click" this.shareYourThoughtsClicked}}
          >
            {{~icon "pencil-alt"~}}
            <span class="composer-prompt-text">
              {{i18n "agc.composer.prompt"}}
            </span>
          </button>
        {{/if}}
      
    */
    {
      "id": "N+V3zc7c",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"composer-prompt\"],[24,4,\"button\"],[4,[32,0],[\"click\",[30,0,[\"shareYourThoughtsClicked\"]]],null],[12],[1,[28,[32,1],[\"pencil-alt\"],null]],[10,1],[14,0,\"composer-prompt-text\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"agc.composer.prompt\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/share-your-thoughts.js",
      "scope": () => [_modifier.on, _dIcon.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcDiscoveryShareYourThoughts;
});