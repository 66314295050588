define("discourse/plugins/discourse-global-communities/discourse/connectors/user-preferences-notifications/notifications", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NotificationsPreferences extends _component.default {
    static shouldRender(_, component) {
      return component.currentUser?.agcFeatureFlags?.enhanced_notifications_enabled;
    }
  }
  _exports.default = NotificationsPreferences;
});