define("discourse/plugins/discourse-global-communities/discourse/initializers/topic-list-comment", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "I18n"], function (_exports, _pluginApi, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-topic-list-comment",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.modifyClass("component:topic-list-item", dt7948.p({
          pluginId: "agc-base-component topic-list-comment",
          commentsLabel(replyCount, settingEnabled) {
            if (!settingEnabled) {
              return;
            }
            return _I18n.default.t("agc.topic-list.comment", {
              count: replyCount
            });
          }
        }, [["method", "commentsLabel", [(0, _decorators.default)("topic.replyCount", "siteSettings.comments_label_on_topic_list")]]]));
      });
    }
  };
});