define("discourse/plugins/discourse-global-communities/discourse/controllers/program-pillars", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/service", "I18n"], function (_exports, _tracking, _controller, _service, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PROGRAM_PILLARS_ROUTE_NAME = "program-pillars";
  class ProgramPillarsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    locale = (() => _I18n.default.currentLocale())();
    constructor() {
      super(...arguments);
      this.appEvents.on("agc-custom:filter-select", this, this._handleFilterSelect);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("agc-custom:filter-select", this, this._handleFilterSelect);
    }
    get tagTranslations() {
      if (this.siteSettings.program_pillar_translations) {
        try {
          return JSON.parse(this.siteSettings.program_pillar_translations).reduce((result, _ref) => {
            let {
              key,
              translations
            } = _ref;
            result[key] = {
              translations
            };
            return result;
          }, {});
        } catch {}
      } else {
        return {};
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "tagTranslations", [_tracking.cached]))();
    get descriptionKey() {
      return `program_pillars.landing_page_description_${this.model.programPillar.region}`;
    }
    get topWidgetTitle() {
      const count = this.model.programPillar.top_widget_topics.length;
      if (this.t) {
        const tag = this._getTagTranslation(this.childTagForTagName(this.t)?.translation_key) || this.t;
        return _I18n.default.t("program_pillars.top_widget_title_tag", {
          tag,
          count
        });
      }
      return _I18n.default.t("program_pillars.top_widget_title_all", {
        count
      });
    }
    get bottomWidgetTitle() {
      return _I18n.default.t("program_pillars.bottom_widget_title", {
        count: this.model.programPillar.bottom_widget_topics.length
      });
    }
    childTagForTagName(tagName) {
      return this.model.programPillar.child_tags.find(ct => ct.tag_name === tagName);
    }
    get tagOptions() {
      const allOption = {
        id: "all",
        label: _I18n.default.t("program_pillars.all_option_label"),
        count: this.model.programPillar.total_count
      };
      const childTags = this.model.programPillar.child_tags.map(t => {
        return {
          id: t.tag_name,
          label: this._getTagTranslation(t.translation_key),
          count: t.count
        };
      });
      return [allOption, ...childTags];
    }
    get defaultTagOption() {
      return this.tagOptions[0].id;
    }
    _getTagTranslation(translationKey) {
      return this.tagTranslations[translationKey]?.translations.find(t => t.locale === this.locale)?.translated || translationKey;
    }
    _handleFilterSelect(_ref2) {
      let {
        scope,
        selected
      } = _ref2;
      if (scope !== PROGRAM_PILLARS_ROUTE_NAME) {
        return;
      }
      selected === this.defaultTagOption || selected === this.get("t") ? this.set("t", "") : this.set("t", selected);
    }
    get hasHero() {
      return !!this.model.programPillar.hero_upload;
    }
    get hasWidgets() {
      return this.model.programPillar.top_widget_topics.length || this.model.programPillar.bottom_widget_topics.length;
    }
    get widgets() {
      const widgets = [];
      if (this.model.programPillar.top_widget_topics.length) {
        const [topWidgetTopics, topWidgetAdditionalTopics] = this.#splitWidgetTopics(this.model.programPillar.top_widget_topics);
        widgets.push({
          title: this.topWidgetTitle,
          topics: topWidgetTopics,
          additionalTopics: topWidgetAdditionalTopics,
          class: "program-pillars-top-widget"
        });
      }
      if (this.model.programPillar.bottom_widget_topics.length) {
        const [bottomWidgetTopics, bottomWidgetAdditionalTopics] = this.#splitWidgetTopics(this.model.programPillar.bottom_widget_topics);
        widgets.push({
          title: this.bottomWidgetTitle,
          topics: bottomWidgetTopics,
          additionalTopics: bottomWidgetAdditionalTopics,
          class: "program-pillars-bottom-widget"
        });
      }
      return widgets;
    }
    #splitWidgetTopics(topics) {
      if (this.site.mobileView) {
        return [topics, null];
      }
      return [topics.slice(0, 5), topics.slice(5)];
    }
  }
  _exports.default = ProgramPillarsController;
});