define("discourse/plugins/discourse-global-communities/discourse/controllers/tv-index", ["exports", "@ember/controller", "discourse/models/topic", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _topic, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    seeAllText: _I18n.default.t("agc.tv.see_all"),
    sections(unprocessedSections) {
      return unprocessedSections.map(unprocessedSection => {
        const label = unprocessedSection.new ? _I18n.default.t("agc.tv.new_and_unwatched") : unprocessedSection.label;
        return {
          label,
          topics: unprocessedSection.topics.map(topic => _topic.default.create(topic)),
          showSeeAll: unprocessedSection.topics.length > this.siteSettings.tv_carousel_tiles_per_page,
          newAndUnread: unprocessedSection.new,
          seeAllHref: this._getSeeAllHrefFor(unprocessedSection)
        };
      });
    },
    _getSeeAllHrefFor(section) {
      if (section.new) {
        return `/tv/new`;
      }
      if (section.see_all === "list") {
        return `/tv/${section.tag}`;
      } else if (!isNaN(section.see_all)) {
        return `/t/${section.see_all}`;
      }
      return section.see_all;
    }
  }, [["method", "sections", [(0, _decorators.default)("model.sections")]]]));
});