define("discourse/plugins/discourse-global-communities/discourse/components/gc-mini-webinar", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-global-communities/discourse/helpers/formatted"], function (_exports, _component, _decorators, _I18n, _formatted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    scheduled(webinar) {
      if (webinar === undefined) {
        return "";
      }
      if (webinar.ends_at === null) {
        return moment(webinar.starts_at).format(_I18n.default.t("dates.full_with_year_no_time"));
      }
      return (0, _formatted.formattedSchedule)(webinar.starts_at, webinar.ends_at);
    },
    includeWebinarRegister(starts_at) {
      return moment(starts_at).isAfter(moment());
    }
  }, [["method", "scheduled", [(0, _decorators.default)("webinar")]], ["method", "includeWebinarRegister", [(0, _decorators.default)("webinar.starts_at")]]]));
});