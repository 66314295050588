define("discourse/plugins/discourse-global-communities/discourse/components/tv-hero", ["exports", "@ember/component", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer"], function (_exports, _component, _videojsInitializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["tv-hero"],
    didInsertElement() {
      this._super(...arguments);
      const videoEl = this.element.querySelector(".tv-hero-video-container video");
      if (videoEl) {
        (0, _videojsInitializer.default)(videoEl, this.appEvents);
      }
    }
  });
});