define("discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer", ["exports", "discourse/lib/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(videoTag, appEvents) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (!opts.noClasses) {
      videoTag.classList.add("my-video", "video-js", "vjs-default-skin", "vjs-big-play-centered");
    }
    return (0, _loadScript.default)("/plugins/discourse-global-communities/javascripts/videojs.js").then(() => {
      const video = window.videojs(videoTag, {
        controls: true,
        autoplay: false,
        preload: "auto",
        loadingSpinner: false,
        playsinline: true
      });
      appEvents.trigger("discourse-media:start-tracking-videojs", video);
      return video;
    });
  }
});