define("discourse/plugins/discourse-global-communities/discourse/initializers/saved-posts", ["exports", "discourse/lib/notification-levels", "discourse/lib/plugin-api", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "I18n"], function (_exports, _notificationLevels, _pluginApi, _getUrl, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-saved-posts",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.29.0", api => {
        replaceBookmarkButton(api);
        addNotifyButtonToPostMenu(api);
        api.addQuickAccessProfileItem({
          href: (0, _getUrl.default)("/my/activity/bookmarks"),
          className: "bookmarks-quick-access-item",
          icon: "bookmark-squid",
          content: _I18n.default.t("agc.navs.saved_items")
        });
        api.modifyClass("component:topic-list-item", dt7948.p({
          pluginId: "discourse-global-communities rerendering",
          reRenderTopicListItem() {
            this.renderTopicListItem();
          },
          toggleDeletedAddClass() {
            this.renderTopicListItem();
            this.element.classList.toggle("-deleted", this.topic.deleted_at);
          }
        }, [["method", "reRenderTopicListItem", [(0, _decorators.observes)("topic.bookmarked")]], ["method", "toggleDeletedAddClass", [(0, _decorators.observes)("topic.deleted_at")]]]));
      });
    }
  };
  function replaceBookmarkButton(api) {
    const agcBookmarkService = api.container.lookup("service:agc-bookmarks");
    api.removePostMenuButton("bookmark");
    api.addPostMenuButton("agc-saved-post", attrs => {
      return {
        icon: attrs.bookmarked ? "unbookmark-squid" : "bookmark-squid",
        className: attrs.bookmarked ? "save-post-btn -unsave" : "save-post-btn -save",
        title: attrs.bookmarked ? "saved_posts.unsave" : "saved_posts.save",
        action: attrs.bookmarked ? "agcUnsavePost" : "agcSavePost",
        position: "second"
      };
    });
    api.attachWidgetAction("post", "agcSavePost", function () {
      return agcBookmarkService.bookmarkPost(this.model.topic, this.model);
    });
    api.attachWidgetAction("post", "agcUnsavePost", function () {
      return agcBookmarkService.clearBookmarkForPost(this.model);
    });
  }
  function addNotifyButtonToPostMenu(api) {
    const watchingLevel = _notificationLevels.topicLevels.find(tl => tl.key === "watching")?.id;
    const agcTopicListWatching = api.container.lookup("service:agc-topic-list-watching");
    const currentUser = api.getCurrentUser();
    api.includePostAttributes("topicNotificationLevel");
    api.addPostMenuButton("agc-notify-btn", attrs => {
      if (attrs.post_number !== 1 || attrs.user?.id === currentUser?.id) {
        return;
      }
      const watching = attrs.topicNotificationLevel === watchingLevel;
      return {
        icon: watching ? "d-watching" : "d-regular",
        className: `toggle-watching-btn ${watching ? "-watching" : "-regular"}`,
        title: `notification_levels.${watching ? "notified" : "notify"}`,
        label: `notification_levels.${watching ? "notified" : "notify"}`,
        action: "toggleWatching",
        position: "second"
      };
    });
    api.attachWidgetAction("post", "toggleWatching", function () {
      return agcTopicListWatching.toggleWatching(this.model.topic);
    });
  }
});