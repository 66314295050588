define("discourse/plugins/discourse-global-communities/discourse/mixins/topic-list-like", ["exports", "jquery", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _jquery, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    didInsertElement() {
      this._super(...arguments);
      (0, _jquery.default)(this.element).on("click", ".meta-likes.can-like", () => {
        this.send("toggleLikes");
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _jquery.default)(this.element).off("click", ".meta-likes.can-like");
    },
    heartIcon(canLike, firstPostLiked) {
      return firstPostLiked ? "d-liked" : !canLike ? "heart-disabled-squid" : "d-unliked";
    },
    canBeLiked(canLike) {
      return canLike ? "can-like" : "";
    },
    actions: {
      toggleLikes() {
        const postId = this.get("topic.topic_first_post_id"),
          firstPostLiked = this.get("topic.topic_first_post_liked");
        let likeCount = this.get("topic.like_count");
        if (firstPostLiked) {
          (0, _ajax.ajax)(`/post_actions/${postId}`, {
            type: "DELETE",
            data: {
              post_action_type_id: 2
            }
          }).then(() => {
            likeCount -= 1;
            this.set("topic.topic_first_post_liked", false);
            this.set("topic.like_count", likeCount < 0 ? 0 : likeCount);
            if (this.renderTopicListItem) {
              this.renderTopicListItem();
            }
          }).catch(function (error) {
            (0, _ajaxError.popupAjaxError)(error);
          });
        } else {
          (0, _ajax.ajax)("/post_actions", {
            type: "POST",
            data: {
              id: postId,
              post_action_type_id: 2
            }
          }).then(() => {
            this.set("topic.topic_first_post_liked", true);
            this.set("topic.like_count", likeCount + 1);
            if (this.renderTopicListItem) {
              this.renderTopicListItem();
            }
          }).catch(function (error) {
            (0, _ajaxError.popupAjaxError)(error);
          });
        }
      }
    }
  }, [["method", "heartIcon", [(0, _decorators.default)("topic.topic_post_can_like", "topic.topic_first_post_liked")]], ["method", "canBeLiked", [(0, _decorators.default)("topic.topic_post_can_like")]]]);
});