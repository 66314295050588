define("discourse/plugins/discourse-global-communities/discourse/templates/components/agc-quick-search-menu-results", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    role="button"
    {{did-update
      this.clearResultsIfTermIsInvalid
      this.search.activeGlobalSearchTerm
    }}
  >
    {{#if
      (and
        (not @searchTopics) (not this.search.inTopicContext) (not this._loading)
      )
    }}
      {{#if this.topicResults.length}}
        <SearchMenu::Results::Types
          @resultTypes={{this.formattedTopicResults}}
          @topicResultsOnly={{true}}
          @closeSearchMenu={{@closeSearchMenu}}
        />
      {{/if}}
  
      {{#if this.tvResults.length}}
        <SearchMenu::Results::Types
          @resultTypes={{this.formattedTvResults}}
          @topicResultsOnly={{false}}
          @closeSearchMenu={{@closeSearchMenu}}
        />
      {{/if}}
  
      {{#if this.circleResults.length}}
        <SearchMenu::Results::Types
          @resultTypes={{this.formattedCircleResults}}
          @topicResultsOnly={{false}}
          @closeSearchMenu={{@closeSearchMenu}}
        />
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "a3Px/nt2",
    "block": "[[[11,0],[24,\"role\",\"button\"],[4,[38,0],[[30,0,[\"clearResultsIfTermIsInvalid\"]],[30,0,[\"search\",\"activeGlobalSearchTerm\"]]],null],[12],[1,\"\\n\"],[41,[28,[37,2],[[28,[37,3],[[30,1]],null],[28,[37,3],[[30,0,[\"search\",\"inTopicContext\"]]],null],[28,[37,3],[[30,0,[\"_loading\"]]],null]],null],[[[41,[30,0,[\"topicResults\",\"length\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@resultTypes\",\"@topicResultsOnly\",\"@closeSearchMenu\"],[[30,0,[\"formattedTopicResults\"]],true,[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"tvResults\",\"length\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@resultTypes\",\"@topicResultsOnly\",\"@closeSearchMenu\"],[[30,0,[\"formattedTvResults\"]],false,[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"circleResults\",\"length\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@resultTypes\",\"@topicResultsOnly\",\"@closeSearchMenu\"],[[30,0,[\"formattedCircleResults\"]],false,[30,2]]],null],[1,\"\\n\"]],[]],null]],[]],null],[13]],[\"@searchTopics\",\"@closeSearchMenu\"],false,[\"did-update\",\"if\",\"and\",\"not\",\"search-menu/results/types\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/agc-quick-search-menu-results.hbs",
    "isStrictMode": false
  });
});