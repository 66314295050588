define("discourse/plugins/discourse-global-communities/discourse/initializers/topic-list-webinar-register", ["exports", "jquery", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-global-communities/discourse/helpers/formatted"], function (_exports, _jquery, _ajax, _ajaxError, _pluginApi, _decorators, _I18n, _formatted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STARTED = "started";
  const ENDED = "ended";
  var _default = _exports.default = {
    name: "agc-topic-list-webinar-register",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.modifyClass("component:topic-list-item", dt7948.p({
          pluginId: "agc-base-component topic-list-webinar-register",
          didInsertElement() {
            this._super(...arguments);
            if (this.siteSettings.zoom_enabled) {
              const webinar = this.get("topic.webinar");
              if (webinar) {
                this.setProperties({
                  webinar,
                  loading: false,
                  canRegister: this.registrationOpen(webinar) && !this.isHost(webinar) && !this.isPanelist(webinar) && !this.isAttendee(webinar)
                });
                (0, _jquery.default)(this.element).on("click", ".webinar-register-button", () => {
                  this.toggleRegistration(true);
                });
                (0, _jquery.default)(this.element).on("click", ".webinar-unregister-button", () => {
                  this.toggleRegistration(false);
                });
                (0, _jquery.default)(this.element).on("click", ".webinar-join-sdk", () => {
                  this.joinSDK();
                });
              }
            }
          },
          webinarStarted(webinar) {
            if (webinar.status) {
              const beforeStart = this.siteSettings.zoom_join_x_mins_before_start;
              if (webinar.status === STARTED) {
                if (!beforeStart) {
                  return true;
                }
                const newStartTime = moment(webinar.starts_at).subtract(beforeStart, "minutes");
                if (newStartTime.isBefore(moment())) {
                  return true;
                }
              }
              return false;
            }
          },
          webinarEnded(status) {
            return status === ENDED;
          },
          webinarTime(webinar) {
            if (webinar.ends_at === null) {
              return moment(webinar.starts_at).format(_I18n.default.t("dates.full_with_year_no_time"));
            }
            return (0, _formatted.formattedSchedule)(webinar.starts_at, webinar.ends_at);
          },
          registrationOpen(webinar) {
            const w = webinar || this.webinar;
            if (w && w.starts_at && w.ends_at) {
              return moment(w.starts_at).isAfter(moment());
            }
            return false;
          },
          isAttendee(webinar) {
            const w = webinar || this.webinar;
            if (w && w.attendees) {
              for (let attendee of w.attendees) {
                if (attendee.id === this.currentUser.id) {
                  return true;
                }
              }
            }
            return false;
          },
          isHost(webinar) {
            const w = webinar || this.webinar;
            if (w && w.host) {
              return this.currentUser.id === w.host.id;
            }
            return false;
          },
          isPanelist(webinar) {
            const w = webinar || this.webinar;
            if (w.panelists) {
              for (let panelist of w.panelists) {
                if (panelist.id === this.currentUser.id) {
                  return true;
                }
              }
            }
            return false;
          },
          toggleRegistration(registering) {
            const method = registering ? "PUT" : "DELETE";
            return (0, _ajax.ajax)(`/zoom/webinars/${this.webinar.id}/attendees/${this.currentUser.username}.json`, {
              type: method
            }).then(() => {
              this.setProperties({
                canRegister: !registering,
                isAttendee: registering,
                loading: false
              });
              this.renderTopicListItem();
            }).catch(function (error) {
              (0, _ajaxError.popupAjaxError)(error);
            });
          },
          joinSDK() {
            const url = this.siteSettings.zoom_enable_sdk_fallback ? `/zoom/webinars/${this.webinar.id}/sdk?fallback=1` : `/zoom/webinars/${this.webinar.id}/sdk`;
            if (this.registered) {
              window.location.href = url;
            } else {
              this.toggleRegistration(true).then(() => {
                window.location.href = url;
              });
            }
          },
          willDestroyElement() {
            this._super(...arguments);
            if (this.siteSettings.zoom_enabled) {
              (0, _jquery.default)(this.element).off("click", ".webinar-register-button");
              (0, _jquery.default)(this.element).off("click", ".webinar-unregister-button");
              (0, _jquery.default)(this.element).off("click", ".webinar-join-sdk");
            }
          }
        }, [["method", "webinarStarted", [(0, _decorators.default)("topic.webinar")]], ["method", "webinarEnded", [(0, _decorators.default)("topic.webinar.status")]], ["method", "webinarTime", [(0, _decorators.default)("topic.webinar")]]]));
      });
    }
  };
});