define("discourse/plugins/discourse-global-communities/discourse/templates/admin/plugins-agc-contacts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-contacts__admin-container">
    <div class="agc-contacts__admin-controls">
      <ComboBox
        @id="agc-contacts-csv-group"
        @disabled={{this.saving}}
        @valueProperty="group_name"
        @nameProperty="group_name"
        @content={{this.availableGroups}}
        @value={{this.groupName}}
        @onChange={{action (mut this.groupName)}}
        @options={{hash
          autoInsertNoneItem=false
          none="amazon_contacts.admin.group_none"
        }}
      />
    </div>
  
    <AceEditor
      data-group-name={{this.groupName}}
      @content={{this.content}}
      @disabled={{this.isEditorDisabled}}
      @mode="text"
    />
  
    {{#unless this.loading}}
      {{#if this.isGroupApiOnly}}
        <div class="agc-contacts__admin-notice">
          {{i18n "amazon_contacts.admin.api_only"}}
        </div>
      {{else}}
        <div class="agc-contacts__admin-buttons">
          <SaveControls
            @model={{this}}
            @action={{action "save"}}
            @saveDisabled={{this.isSaveDisabled}}
            @saved={{this.isSaved}}
          />
        </div>
      {{/if}}
    {{/unless}}
  </div>
  */
  {
    "id": "cxaz7NZB",
    "block": "[[[10,0],[14,0,\"agc-contacts__admin-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"agc-contacts__admin-controls\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@id\",\"@disabled\",\"@valueProperty\",\"@nameProperty\",\"@content\",\"@value\",\"@onChange\",\"@options\"],[\"agc-contacts-csv-group\",[30,0,[\"saving\"]],\"group_name\",\"group_name\",[30,0,[\"availableGroups\"]],[30,0,[\"groupName\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"groupName\"]]],null]],null],[28,[37,3],null,[[\"autoInsertNoneItem\",\"none\"],[false,\"amazon_contacts.admin.group_none\"]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,4],[[16,\"data-group-name\",[30,0,[\"groupName\"]]]],[[\"@content\",\"@disabled\",\"@mode\"],[[30,0,[\"content\"]],[30,0,[\"isEditorDisabled\"]],\"text\"]],null],[1,\"\\n\\n\"],[41,[51,[30,0,[\"loading\"]]],[[[41,[30,0,[\"isGroupApiOnly\"]],[[[1,\"      \"],[10,0],[14,0,\"agc-contacts__admin-notice\"],[12],[1,\"\\n        \"],[1,[28,[35,7],[\"amazon_contacts.admin.api_only\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"agc-contacts__admin-buttons\"],[12],[1,\"\\n        \"],[8,[39,8],null,[[\"@model\",\"@action\",\"@saveDisabled\",\"@saved\"],[[30,0],[28,[37,1],[[30,0],\"save\"],null],[30,0,[\"isSaveDisabled\"]],[30,0,[\"isSaved\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]],null],[13]],[],false,[\"combo-box\",\"action\",\"mut\",\"hash\",\"ace-editor\",\"unless\",\"if\",\"i18n\",\"save-controls\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/admin/plugins-agc-contacts.hbs",
    "isStrictMode": false
  });
});