define("discourse/plugins/discourse-global-communities/discourse/components/agc/contacts/row", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-contacts__row">
    {{#if @avatar}}
      <div class="agc-contacts__row-avatar">{{html-safe @avatar}}</div>
    {{/if}}
    <div class="agc-contacts__row-data">
      <div class="agc-contacts__row-name">{{@name}}</div>
      <div class="agc-contacts__row-title">{{@title}}</div>
  
      <div class="agc-contacts__row-actions">
        {{#if @email}}
          <a
            class="agc-contacts__row-contact-link"
            data-contact-type="email"
            data-contact-name={{@name}}
            data-contact-title={{@title}}
            data-contact-info={{@email}}
            href="mailto:{{@email}}"
          >
            {{d-icon "mailbox-squid"}}
            Email
          </a>
        {{/if}}
        {{#if @phone}}
          <a
            class="agc-contacts__row-contact-link"
            data-contact-type="phone"
            data-contact-name={{@name}}
            data-contact-title={{@title}}
            data-contact-info={{@phone}}
            href="tel:{{@phone}}"
          >
            {{d-icon "phone-squid"}}
            {{@phone}}
          </a>
        {{/if}}
        {{#if @chime}}
          <a
            class="agc-contacts__row-contact-link"
            data-contact-type="chime"
            data-contact-name={{@name}}
            data-contact-title={{@title}}
            data-contact-info={{@chime}}
            href={{@chime}}
          >
            {{d-icon "chime-squid"}}
            Chime me
          </a>
        {{/if}}
      </div>
    </div>
  </div>
  */
  {
    "id": "DWTt7GBa",
    "block": "[[[10,0],[14,0,\"agc-contacts__row\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"agc-contacts__row-avatar\"],[12],[1,[28,[35,1],[[30,1]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"agc-contacts__row-data\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"agc-contacts__row-name\"],[12],[1,[30,2]],[13],[1,\"\\n    \"],[10,0],[14,0,\"agc-contacts__row-title\"],[12],[1,[30,3]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"agc-contacts__row-actions\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,3],[14,0,\"agc-contacts__row-contact-link\"],[14,\"data-contact-type\",\"email\"],[15,\"data-contact-name\",[30,2]],[15,\"data-contact-title\",[30,3]],[15,\"data-contact-info\",[30,4]],[15,6,[29,[\"mailto:\",[30,4]]]],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"mailbox-squid\"],null]],[1,\"\\n          Email\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,5],[[[1,\"        \"],[10,3],[14,0,\"agc-contacts__row-contact-link\"],[14,\"data-contact-type\",\"phone\"],[15,\"data-contact-name\",[30,2]],[15,\"data-contact-title\",[30,3]],[15,\"data-contact-info\",[30,5]],[15,6,[29,[\"tel:\",[30,5]]]],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"phone-squid\"],null]],[1,\"\\n          \"],[1,[30,5]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,6],[[[1,\"        \"],[10,3],[14,0,\"agc-contacts__row-contact-link\"],[14,\"data-contact-type\",\"chime\"],[15,\"data-contact-name\",[30,2]],[15,\"data-contact-title\",[30,3]],[15,\"data-contact-info\",[30,6]],[15,6,[30,6]],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"chime-squid\"],null]],[1,\"\\n          Chime me\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@avatar\",\"@name\",\"@title\",\"@email\",\"@phone\",\"@chime\"],false,[\"if\",\"html-safe\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/contacts/row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});