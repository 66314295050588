define("discourse/plugins/discourse-global-communities/discourse/helpers/category-expert-topic-list-pills", ["exports", "@ember/template", "discourse-common/lib/helpers", "I18n"], function (_exports, _template, _helpers, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = categoryExpertTopicListPills;
  (0, _helpers.registerRawHelper)("category-expert-topic-list-pills", categoryExpertTopicListPills);
  function categoryExpertTopicListPills(topic, amazonianGroupName) {
    let amazonian = false;
    let other = false;
    let html = "";
    topic.expert_post_group_names.forEach(groupName => {
      if (groupName.toLowerCase() === amazonianGroupName?.toLowerCase()) {
        amazonian = true;
      } else {
        other = true;
      }
    });
    if (amazonian) {
      html += `<a data-agc-analytics-ref="amazonian-guide-pill" href="${topic.firstPostUrl}?filter=amazon_responses" class='topic-list-category-expert-response category-expert-response-amazonian' data-agc-analytics-ref='pill_amazon_response'>${_I18n.default.t("agc.agc_category_experts.amazonian_responses")}</a>`;
    }
    if (other) {
      html += `<a data-agc-analytics-ref="dsp-guide-pill" href="${topic.firstPostUrl}?filter=guided_responses" class='topic-list-category-expert-response category-expert-response-dsp-guide' data-agc-analytics-ref='pill_guide_response'>${_I18n.default.t("agc.agc_category_experts.dsp_guide_responses")}</a>`;
    }
    return (0, _template.htmlSafe)(html);
  }
});