define("discourse/plugins/discourse-global-communities/discourse/widgets/topic-list-actions", ["virtual-dom", "discourse/lib/ajax", "discourse/lib/formatter", "discourse/widgets/widget", "discourse-common/lib/icon-library", "I18n"], function (_virtualDom, _ajax, _formatter, _widget, _iconLibrary, _I18n) {
  "use strict";

  (0, _widget.createWidget)("topic-list-actions", {
    tagName: "",
    html(attrs) {
      const topic = attrs.topic;
      const items = [this.attach("topic-list-actions-like", {
        topic
      })];
      items.push((0, _virtualDom.h)("span.views", [(0, _iconLibrary.iconNode)("eye-squid"), (0, _virtualDom.h)("span.view-count", (0, _formatter.number)(topic.views)), (0, _virtualDom.h)("span.views-label", _I18n.default.t("agc.views"))]));
      return (0, _virtualDom.h)("div.tv-episode-actions", items);
    }
  });
  (0, _widget.createWidget)("topic-list-actions-like", {
    tagName: "span.topic-list-actions-like",
    loading: false,
    html(attrs) {
      const topic = attrs.topic;
      return (0, _virtualDom.h)(`span.like.${topic.topic_post_can_like ? "can-click" : "cant-click"}`, [this.likeIcon(topic), (0, _virtualDom.h)("span.like-count", (0, _formatter.number)(topic.like_count))]);
    },
    likeIcon(topic) {
      if (topic.topic_first_post_liked) {
        return (0, _iconLibrary.iconNode)("d-liked");
      } else {
        return (0, _iconLibrary.iconNode)(topic.topic_post_can_like ? "d-unliked" : "heart-disabled-squid");
      }
    },
    click() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const topic = this.attrs.topic;
      if (!topic.topic_first_post_liked && !topic.topic_post_can_like) {
        return;
      }
      const postId = topic.topic_first_post_id;
      const firstPostLiked = topic.topic_first_post_liked;
      const url = firstPostLiked ? `/post_actions/${postId}` : "/post_actions";
      const type = firstPostLiked ? "DELETE" : "POST";
      const data = firstPostLiked ? {
        post_action_type_id: 2
      } : {
        id: postId,
        post_action_type_id: 2
      };
      (0, _ajax.ajax)(url, {
        type,
        data
      }).then(() => {
        topic.topic_first_post_liked = !firstPostLiked;
        topic.like_count = firstPostLiked ? topic.like_count - 1 < 0 ? 0 : topic.like_count - 1 : topic.like_count + 1;
        this.loading = false;
        this.scheduleRerender();
      });
    }
  });
});