define("discourse/plugins/discourse-global-communities/discourse/components/circles/heading-name", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <h2>{{@outletArgs.model.name}}</h2>
  
  */
  {
    "id": "u0nN7Juj",
    "block": "[[[1,\"\\n  \"],[10,\"h2\"],[12],[1,[30,1,[\"model\",\"name\"]]],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/circles/heading-name.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});