define("discourse/plugins/discourse-global-communities/discourse/components/carousel-v2-pagination", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO (while_away_loading_indicator) CLEANUP: remove this file once we remove the feature flag
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["carousel-pagination"],
    navigate() {
      if (this.get("disabledClass") !== "disabled") {
        this.action();
      }
    }
  }, [["method", "navigate", [_object.action]]]));
});