define("discourse/plugins/discourse-global-communities/discourse/controllers/admin-plugins-agc-emails-notifications-summary", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax-error", "discourse/plugins/discourse-global-communities/discourse/models/email-preview-notifications-summary"], function (_exports, _tracking, _controller, _object, _computed, _service, _ajaxError, _emailPreviewNotificationsSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailPreviewDigestController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "username", [_tracking.tracked], function () {
      return null;
    }))();
    #username = (() => (dt7948.i(this, "username"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "frequency", [_tracking.tracked], function () {
      return null;
    }))();
    #frequency = (() => (dt7948.i(this, "frequency"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "lastSeen", [_tracking.tracked], function () {
      return null;
    }))();
    #lastSeen = (() => (dt7948.i(this, "lastSeen"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "lastAttempt", [_tracking.tracked], function () {
      return null;
    }))();
    #lastAttempt = (() => (dt7948.i(this, "lastAttempt"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "sentEmail", [_tracking.tracked], function () {
      return null;
    }))();
    #sentEmail = (() => (dt7948.i(this, "sentEmail"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "sendingEmail", [_tracking.tracked], function () {
      return null;
    }))();
    #sendingEmail = (() => (dt7948.i(this, "sendingEmail"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "emailEmpty", [(0, _computed.empty)("email")]))();
    #emailEmpty = (() => (dt7948.i(this, "emailEmpty"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "sendEmailDisabled", [(0, _computed.or)("emailEmpty", "sendingEmail")]))();
    #sendEmailDisabled = (() => (dt7948.i(this, "sendEmailDisabled"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "showSendEmailForm", [(0, _computed.notEmpty)("model.html_content")]))();
    #showSendEmailForm = (() => (dt7948.i(this, "showSendEmailForm"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "htmlEmpty", [(0, _computed.empty)("model.html_content")]))();
    #htmlEmpty = (() => (dt7948.i(this, "htmlEmpty"), void 0))();
    toggleShowHtml(event) {
      event?.preventDefault();
      this.toggleProperty("showHtml");
    }
    static #_12 = (() => dt7948.n(this.prototype, "toggleShowHtml", [_object.action]))();
    updateUsername(selected) {
      this.username = (0, _object.get)(selected, "firstObject");
    }
    static #_13 = (() => dt7948.n(this.prototype, "updateUsername", [_object.action]))();
    refresh() {
      const model = this.model;
      this.loading = true;
      this.sentEmail = false;
      let username = this.username;
      if (!username) {
        username = this.currentUser.get("username");
        this.username = username;
      }
      _emailPreviewNotificationsSummary.default.findNotificationsSummary(username, this.lastSeen, this.lastAttempt, this.frequency).then(email => {
        model.setProperties(email.getProperties("html_content", "text_content"));
        this.loading = false;
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
    sendEmail() {
      this.sendingEmail = true;
      this.sentEmail = false;
      _emailPreviewNotificationsSummary.default.sendNotificationsSummary(this.username, this.lastSeen, this.lastAttempt, this.frequency, this.email).then(result => {
        if (result.errors) {
          this.dialog.alert(result.errors);
        } else {
          this.sentEmail = true;
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.sendingEmail = false;
      });
    }
    static #_15 = (() => dt7948.n(this.prototype, "sendEmail", [_object.action]))();
  }
  _exports.default = AdminEmailPreviewDigestController;
});