define("discourse/plugins/discourse-global-communities/discourse/templates/components/gc-carousel-nav", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    role="button"
    class="control {{disabledClass}}"
    {{action "triggerOnClick"}}
  >
    {{d-icon icon}}
  </div>
  */
  {
    "id": "FRvuDAyG",
    "block": "[[[44,[[28,[37,1],null,[[\"disabledClass\"],[[28,[32,0],[\"disabledClass\"],null]]]]],[[[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"control \",[52,[30,1,[\"disabledClass\"]],[28,[30,1,[\"disabledClass\"]],null,null],[28,[32,1],[[30,0],\"disabledClass\",\"[\\\"The `disabledClass` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/gc-carousel-nav.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.disabledClass}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[4,[38,3],[[30,0],\"triggerOnClick\"],null],[12],[1,\"\\n  \"],[1,[28,[35,4],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"],[13]],[1]]],[1,[28,[32,2],[\"[[\\\"The `icon` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/gc-carousel-nav.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.icon}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"let\",\"hash\",\"if\",\"action\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/gc-carousel-nav.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});