define("discourse/plugins/discourse-global-communities/discourse/connectors/above-user-profile/preferences", ["exports", "@glimmer/component", "@ember/application"], function (_exports, _component, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AGCPreferencesTitle extends _component.default {
    static shouldRender(_, component) {
      const isPreferencesPage = (0, _application.getOwner)(component).lookup("service:router").currentRouteName.includes("preferences.");
      return isPreferencesPage && component.currentUser?.agcFeatureFlags?.enhanced_notifications_enabled;
    }
  }
  _exports.default = AGCPreferencesTitle;
});