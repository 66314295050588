define("discourse/plugins/discourse-global-communities/discourse/components/agc/header/nav-item", ["exports", "discourse/plugins/discourse-global-communities/discourse/components/agc/header/link", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _link, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AgcHeaderNavItem = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <li ...attributes class="agc-header-icon">
      <AgcHeaderLink
        class="icon btn-flat"
        @href={{@href}}
        @icon={{@icon}}
        @label={{@label}}
        @hideLabel={{@hideLabel}}
        @agcAnalyticsRef={{@agcAnalyticsRef}}
        @onClick={{@onClick}}
        @showIndicator={{@showIndicator}}
      >
        {{yield}}
      </AgcHeaderLink>
    </li>
  
  */
  {
    "id": "9lTDKu+1",
    "block": "[[[1,\"\\n  \"],[11,\"li\"],[17,1],[24,0,\"agc-header-icon\"],[12],[1,\"\\n    \"],[8,[32,0],[[24,0,\"icon btn-flat\"]],[[\"@href\",\"@icon\",\"@label\",\"@hideLabel\",\"@agcAnalyticsRef\",\"@onClick\",\"@showIndicator\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n      \"],[18,9,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@href\",\"@icon\",\"@label\",\"@hideLabel\",\"@agcAnalyticsRef\",\"@onClick\",\"@showIndicator\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/header/nav-item.js",
    "scope": () => [_link.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = AgcHeaderNavItem;
});