define("discourse/plugins/discourse-global-communities/discourse/templates/components/see-all-comments-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if show}}
    {{d-button
      action=(action "onClick")
      translatedLabel=(i18n "agc.responses_filter.see_all_comments")
    }}
  {{/if}}
  */
  {
    "id": "8b0NDDiL",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"action\",\"translatedLabel\"],[[28,[37,2],[[30,0],\"onClick\"],null],[28,[37,3],[\"agc.responses_filter.see_all_comments\"],null]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `show` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/components/see-all-comments-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.show}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\",\"action\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/see-all-comments-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});