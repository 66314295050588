define("discourse/plugins/discourse-global-communities/discourse/initializers/topic-list-like", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator", "discourse/plugins/discourse-global-communities/discourse/mixins/topic-list-like"], function (_exports, _pluginApi, _decorators, _I18n, _analyticsAnnotator, _topicListLike) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-topic-list-like",
    initialize() {
      // add likes to topic list
      // relies on topic_list_item_serializer items from plugin
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        // TODO (while_away_loading_indicator) CLEANUP: remove this modifyClass once we remove the feature flag
        api.modifyClass("component:topic-tile", {
          pluginId: "agc-base-component wywa-topic-tile-like",
          didInsertElement() {
            this.element.addEventListener("click", event => {
              if (event.target.closest(".meta-likes.can-like")) {
                const firstPostLiked = !this.get("topic.topic_first_post_liked");
                if (firstPostLiked) {
                  (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_topic_liked", this.appEvents, {
                    topic_id: this.get("topic.id")
                  });
                }
              }
            });
          }
        });
        api.modifyClass("component:topic-list-item", dt7948.p({
          pluginId: "agc-base-component topic-list-like",
          ..._topicListLike.default,
          likesLabel(likeCount, settingEnabled) {
            if (!settingEnabled) {
              return;
            }
            return _I18n.default.t("agc.topic-list.like", {
              count: likeCount
            });
          }
        }, [["method", "likesLabel", [(0, _decorators.default)("topic.like_count", "siteSettings.likes_label_on_topic_list")]]]));
      });
    }
  };
});