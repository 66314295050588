define("discourse/plugins/discourse-global-communities/discourse/components/gc-carousel-nav", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["carousel-nav"],
    triggerOnClick() {
      if (this.disabledClass !== "disabled") {
        this.onClick();
      }
    }
  }, [["method", "triggerOnClick", [_object.action]]]));
});