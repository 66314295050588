define("discourse/plugins/discourse-global-communities/discourse/widgets/comment-prompt-widget", ["virtual-dom", "discourse/widgets/widget", "discourse-common/lib/icon-library", "I18n"], function (_virtualDom, _widget, _iconLibrary, _I18n) {
  "use strict";

  (0, _widget.createWidget)("comment-prompt", {
    tagName: "button.composer-prompt",
    html(attrs) {
      const prompt = attrs["tv"] ? _I18n.default.t("agc.tv.comment_prompt") : _I18n.default.t("agc.discussion.comment_prompt");
      return [(0, _iconLibrary.iconNode)("pencil-alt"), (0, _virtualDom.h)("span.composer-prompt-text", prompt)];
    },
    click() {
      this.sendWidgetAction("replyToPost");
    }
  });
});