define("discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/chat-disabled", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Agc::Chat::Pages::CallToAction
    @flavor="chat-disabled"
    @title={{i18n "agc.chat.start_chatting.chat_disabled.title"}}
    @message={{i18n "agc.chat.start_chatting.chat_disabled.message"}}
  >
    <a
      href={{get-url "/my/preferences/chat"}}
      class="chat-call-to-action-page__button btn btn-flat"
      title={{i18n "agc.chat.start_chatting.chat_disabled.btn_label"}}
      data-agc-analytics-event="chat:call-to-action:go-to-preferences-btn-clicked"
    >
      {{i18n "agc.chat.start_chatting.chat_disabled.btn_label"}}
    </a>
  </Agc::Chat::Pages::CallToAction>
  */
  {
    "id": "Wd58kshv",
    "block": "[[[8,[39,0],null,[[\"@flavor\",\"@title\",\"@message\"],[\"chat-disabled\",[28,[37,1],[\"agc.chat.start_chatting.chat_disabled.title\"],null],[28,[37,1],[\"agc.chat.start_chatting.chat_disabled.message\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,3],[15,6,[28,[37,2],[\"/my/preferences/chat\"],null]],[14,0,\"chat-call-to-action-page__button btn btn-flat\"],[15,\"title\",[28,[37,1],[\"agc.chat.start_chatting.chat_disabled.btn_label\"],null]],[14,\"data-agc-analytics-event\",\"chat:call-to-action:go-to-preferences-btn-clicked\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"agc.chat.start_chatting.chat_disabled.btn_label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"agc/chat/pages/call-to-action\",\"i18n\",\"get-url\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/chat-disabled.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});