define("discourse/plugins/discourse-global-communities/discourse/initializers/application-route", ["exports", "@ember/object", "@ember/service", "@ember/utils", "discourse/controllers/composer", "discourse/lib/plugin-api", "discourse/models/login-method", "discourse-common/utils/decorators"], function (_exports, _object, _service, _utils, _composer, _pluginApi, _loginMethod, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api) {
    // When a user without a Discourse account tries to authenticate with Amazon,
    // we do nothing as Amazon does not want to show the Login modal with the
    // associated flash error message. This is because the login template has been
    // override with a custom template displaying information to the user that the
    // forum is invite only.
    api.addBeforeAuthCompleteCallback(options => {
      if (options.requires_invite) {
        const loginPage = api.container.lookup("controller:login-page");
        loginPage?.set("inviteRequiredError", true);
        return false;
      }
      return true;
    });
    api.modifyClass("route:application", {
      pluginId: "discourse-global-communities",
      appEvents: (0, _service.inject)(),
      actions: {
        createNewTopicViaParams() {
          if (typeof window.ReactNativeWebView !== "undefined") {
            this.appEvents.on("composer:cancelled", () => {
              window.ReactNativeWebView.postMessage(JSON.stringify({
                discourse_event: "close_composer"
              }));
            });
            this.appEvents.on("topic:created", post => {
              window.ReactNativeWebView.postMessage(JSON.stringify({
                discourse_event: "topic_created",
                id: post?.topic_id,
                title: post.title
              }));
            });
          }
          return this._super(...arguments);
        }
      }
    });
    api.modifyClass("controller:composer", {
      pluginId: "discourse-global-communities",
      capabilities: (0, _service.inject)(),
      keyValueStore: (0, _service.inject)(),
      showToolbar: (0, _object.computed)({
        get() {
          if (document.querySelector(".clear-composer-transitions")) {
            return true;
          }
          const storedVal = this.keyValueStore.get("toolbar-enabled");
          if (this._toolbarEnabled === undefined && storedVal === undefined) {
            // iPhone 6 is 375, anything narrower and toolbar should
            // be default disabled.
            // That said we should remember the state
            this._toolbarEnabled = window.innerWidth > 370 && !this.capabilities.isAndroid;
          }
          return this._toolbarEnabled || storedVal === "true";
        },
        set(key, val) {
          this._toolbarEnabled = val;
          this.keyValueStore.set({
            key: "toolbar-enabled",
            value: val ? "true" : "false"
          });
          return val;
        }
      }),
      actions: {
        cancel() {
          if (document.querySelector(".clear-composer-transitions")) {
            if (typeof window.ReactNativeWebView !== "undefined") {
              window.ReactNativeWebView.postMessage(JSON.stringify({
                discourse_event: "close_composer"
              }));
            }
          } else {
            this.cancelComposer();
          }
        },
        toggle() {
          if (document.querySelector(".clear-composer-transitions")) {
            this.set("currentRouteIndex", this.currentRouteIndex - 1);
            window.history.back();
          } else {
            this.closeAutocomplete();
            if ((0, _utils.isEmpty)(this.get("model.reply")) && (0, _utils.isEmpty)(this.get("model.title"))) {
              this.close();
            } else {
              if (this.get("model.composeState") === _composer.default.OPEN || this.get("model.composeState") === _composer.default.FULLSCREEN) {
                this.shrink();
              } else {
                this.cancelComposer();
              }
            }
            return false;
          }
        }
      }
    });
    api.modifyClass("route:new-topic", {
      pluginId: "discourse-global-communities",
      beforeModel() {
        if (typeof window.ReactNativeWebView !== "undefined") {
          document.documentElement.classList.add("clear-composer-transitions");
        }
        this._super(...arguments);
      }
    });
    api.modifyClass("controller:invites-show", dt7948.p({
      pluginId: "discourse-global-communities",
      site: (0, _service.inject)(),
      _createUserFields() {
        if (!this.site) {
          return;
        }
        let userFields = this.site.get("user_fields");
        if (userFields) {
          userFields = userFields.sortBy("position").map(f => _object.default.create({
            value: null,
            field: f
          }));

          // Takes items out of the middle of an array and appends
          // them to the bottom
          const customAppend = (fieldOptions, itemToAppend) => {
            let index = fieldOptions.indexOf(itemToAppend);
            if (index > -1) {
              fieldOptions.splice(index, 1);
            }
            fieldOptions.push(itemToAppend);
            return fieldOptions;
          };

          // Allows us to sort specific dropdowns alphabetically
          // and places N/A and Other at the bottom of the list
          userFields.forEach(function (f) {
            if (f.get("field.name") === "Station" && f.get("field.options")) {
              let fieldOptions = f.get("field.options").sort();
              fieldOptions = customAppend(fieldOptions, "Other");
              fieldOptions = customAppend(fieldOptions, "N/A");
              f.set("field.options", fieldOptions);
            }
            if (f.get("field.name") === "Station Location" && f.get("field.options")) {
              let fieldOptions = f.get("field.options").sort();
              fieldOptions = customAppend(fieldOptions, "Other");
              fieldOptions = customAppend(fieldOptions, "N/A");
              f.set("field.options", fieldOptions);
            }
          });
        }
        this.set("userFields", userFields);
      },
      agcUserFieldsValidation() {
        let userFields = this.userFields;
        if (userFields) {
          userFields = userFields.filterBy("field.required");
        }
        if (!(0, _utils.isEmpty)(userFields)) {
          const anyEmpty = userFields.any(uf => {
            const val = uf.get("value");
            return !val || (0, _utils.isEmpty)(val);
          });
          if (anyEmpty) {
            return _object.default.create({
              failed: true
            });
          }
        }
        return _object.default.create({
          ok: true
        });
      },
      loginProviders() {
        return (0, _loginMethod.findAll)();
      },
      submitDisabled(emailFailed, usernameFailed, passwordFailed, nameFailed, agcUserFieldsFailed) {
        return emailFailed || usernameFailed || passwordFailed || nameFailed || agcUserFieldsFailed;
      }
    }, [["method", "_createUserFields", [(0, _decorators.on)("init")]], ["method", "agcUserFieldsValidation", [(0, _decorators.default)("userFields.@each.value")]], ["method", "loginProviders", [_decorators.default]], ["method", "submitDisabled", [(0, _decorators.default)("emailValidation.failed", "usernameValidation.failed", "passwordValidation.failed", "nameValidation.failed", "agcUserFieldsValidation.failed")]]]));
  }
  var _default = _exports.default = {
    name: "application-route",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
});